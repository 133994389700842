@import "../_variables";

.home {
    &-text {
        font-size: 16px;
        line-height: 20px;
        color: $color-grey;
    }

    &-section {
        &-container {
            height: 100%;
        }

        &-choice {
            background-image: url("../../images/home/beton-background.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 80vh;

            @include media-breakpoint-down(lg) {
                height: 100%;
                padding: 50px 0;
            }

            &s {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                height: 100%;

                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                }

                &-block {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 550px;
                    border-radius: 20px;
                    padding: 30px 50px 50px;
                    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.1607843137);
                    background-color: #fff;

                    @include media-breakpoint-down(xl) {
                        width: 500px;
                        padding: 0 30px 40px;
                    }

                    @include media-breakpoint-down(lg) {
                        margin: 20px 0;
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }

                    img {
                        @include media-breakpoint-down(xl) {
                            max-width: 240px;
                        }
                    }

                    &-title {
                        font-size: 30px;
                        margin-bottom: 0;
                        white-space: nowrap;

                        @include media-breakpoint-down(xl) {
                            font-size: 28px !important;
                            margin-bottom: 0 !important;
                        }
                    }

                    &-text {
                        margin: 20px 0;
                        text-align: center;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 26px;

                        @include media-breakpoint-down(xl) {
                            font-size: 18px;
                        }
                    }
                }
            }

        }

        &-video {
            margin: 100px 0;
            text-align: center;

            iframe {
                width: 100%;
                height: 700px;

                @include media-breakpoint-down(lg) {
                    height: 450px;
                }

                @include media-breakpoint-down(md) {
                    height: 250px;
                }
            }
        }

        &-banner {
            margin-top: -95px;
            min-height: 95vh;
            background-position: center;
            background-size: cover;
            padding: 20px 0;

            p {
                margin-bottom: 0;
            }

            &-title {
                color: $color-blue;
                padding-top: 20vh;
                font-size: 55px;
                font-weight: bold;
                line-height: 62px;
                margin-bottom: 4rem;

                @include media-breakpoint-down(xl) {
                    font-size: 44px;
                    font-weight: bold;
                    line-height: 48px;
                }

                &-colored {
                    color: $color-green;
                }
            }

            &-reassurance {
                display: flex;
                justify-content: space-around;
                align-items: center;
                background-color: $color-blue;
                border-radius: 20px;
                margin-top: 4rem;
                padding: 24px 20px;

                @include media-breakpoint-down(sm) {
                    margin-top: 6rem;
                }

                &-item {
                    text-align: center;
                    width: calc(33.33333% - 6px);

                    &-title {
                        font-size: 30px;
                        line-height: 38px;
                        color: #fff;
                        font-weight: bold;
                    }
                }

                &-separator {
                    height: 111px;
                    width: 3px;
                    background-color: $color-green;
                    margin: 0 20px;
                }
            }

            @media screen and (max-width: 1199.98px) {
                &-reassurance {
                    &-item-title {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }
            }

            @media screen and (max-width: 991.98px) {
                &-title {
                    padding-top: 20vh;
                }
                &-reassurance {
                    flex-direction: column;
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;

                    &-item {
                        width: auto;

                        &-title {
                            font-size: 22px;
                            line-height: 28px;
                        }
                    }

                    &-separator {
                        height: 2px;
                        width: 70%;
                        margin: 20px 0;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                min-height: auto;
                background-position: top center;
                background-size: 800px 450px;
                background-repeat: no-repeat;
                padding: 20px 0;

                &-title {
                    font-size: 28px;
                    line-height: 31px;
                    padding-top: 110px;
                }
            }

            @media screen and (max-width: 367px) {
                background-size: 800px 500px;
            }
        }

        &-truck {
            margin: 60px auto;

            h2 {
                padding-left: 5vw;
                @media screen and (max-width: 767.98px) {
                    padding-left: 0;
                }
            }

            &-blocks {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 10px;

                &-col {
                    width: 10vw;
                    height: 300px;
                    min-width: calc(33% - 20px);
                }

                a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 300px;
                    height: 300px;
                    border-radius: 20px;
                    margin: auto;
                    padding: 0;
                    text-decoration: none;

                    & > div {
                        height: 58%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                    }

                    img {
                        width: 135px;
                        height: inherit;
                        vertical-align: bottom;

                        @include media-breakpoint-down(sm) {
                            width: 109px;
                        }
                    }

                    p {
                        font-size: 24px;
                        line-height: 26px;
                        color: $color-blue;
                        font-weight: bold;
                        margin-top: 10px;
                        text-align: center;

                        @include media-breakpoint-down(sm) {
                            font-size: 20px;
                        }
                    }

                    &:hover {
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    }
                }

                @include media-breakpoint-down(xl) {
                    &-col {
                        width: 250px;
                        height: 250px;
                    }

                    a {
                        width: 250px;
                        height: 250px;
                    }
                }
                @include media-breakpoint-down(md) {
                    justify-content: center;

                    &-col {
                        width: 220px;
                        height: 220px;
                    }

                    a {
                        width: 220px;
                        height: 220px;
                    }
                }
            }


            &-catalog {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 20px;
                background-color: $color-green;
                text-decoration: none;
                height: 100%;
                color: #fff;
                font-size: 22px;
                font-weight: bold;

                &:hover {
                    color: #fff;
                }

                &-icon {
                    width: 108px;
                    padding: 30px;
                    border-radius: 100px;
                    background-color: #fff;
                    box-shadow: rgba(0, 0, 0, 0.50) 0px 1px 4px;
                    margin-bottom: 30px;

                    &:hover {
                        background-color: $color-green-light;
                    }
                }

                @include media-breakpoint-down(xl) {
                    flex-direction: row;
                    margin-top: 40px;

                    &-icon {
                        width: 90px;
                        padding: 25px;
                        margin: 10px 20px;
                    }
                }
            }
        }

        &-map {
            background: #f5f5f5;
            border-radius: 20px;
            margin: 60px auto;
            padding: 3rem 0;

            @include media-breakpoint-down(sm) {
                margin: 90px auto 0px;
            }

            &-content {
                &-text {
                    font-size: 16px;
                    line-height: 20px;
                    color: $color-grey;
                    margin-bottom: 30px;
                }
            }

            @include media-breakpoint-down(lg) {
                .row {
                    flex-direction: column-reverse;
                }
            }
        }

        &-rental {
            margin: 60px auto;
            padding: 3rem 0;

            @include media-breakpoint-down(sm) {
                margin: 10px auto;
            }

            &-content {
                &-title {
                    text-align: left;
                    font-size: 40px;
                    line-height: 45px;
                    font-weight: bold;
                    color: $color-blue;
                    margin-bottom: 40px;
                }

                &-block {
                    &-subtitle {
                        position: relative;
                        font-weight: bold;
                        font-size: 22px;
                        line-height: 28px;
                        color: $color-green;
                        padding-left: 35px;
                        margin-bottom: 7px;

                        svg {
                            position: absolute;
                            top: 2px;
                            left: 0;
                        }
                    }

                    &-text {
                        font-size: 16px;
                        line-height: 20px;
                        color: $color-grey;
                        padding-left: 35px;

                        svg {
                            color: $color-green;
                        }
                    }

                    .solu-btn {
                        margin-left: 35px;
                        margin-bottom: 40px;
                    }
                }
            }
        }

        &-desc {
            margin: 60px auto;
            padding: 3rem 0;

            @include media-breakpoint-down(sm) {
                margin: 10px auto;
            }

            &-content {
                &-title {
                    text-align: left;
                    font-size: 40px;
                    line-height: 45px;
                    font-weight: bold;
                    color: $color-blue;
                    margin-bottom: 20px;
                }

                &-block {
                    &-desc {
                        color: #484848;
                        font-size: 24px;
                        font-weight: 400;
                        margin-bottom: 40px;
                        line-height: 30px;
                    }

                    &-subtitle {
                        position: relative;
                        font-weight: bold;
                        font-size: 22px;
                        line-height: 28px;
                        color: $color-green;
                        margin-bottom: 10px;

                        svg {
                            position: absolute;
                            top: 2px;
                            left: 0;
                        }
                    }

                    &-checkblock {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;

                        &-icon {
                            color: $color-green;
                            margin-right: 10px;
                            margin-top: 5px;
                        }
                    }

                    &-text {
                        font-size: 16px;
                        line-height: 20px;
                        color: $color-grey;
                        margin-bottom: 10px;

                        svg {
                            color: $color-green;
                            margin-right: 10px;
                        }
                    }

                    .solu-btn {
                        margin-top: 20px;
                        margin-bottom: 40px;
                    }
                }
            }
        }

        &-steps {
            background-color: #f5f5f5;
            border-radius: 10px;
            margin: 60px auto;
            padding: 6rem 0;

            h2 {
                margin-bottom: 20px;
            }

            &-text {
                font-size: 24px;
                color: $color-grey;
                line-height: 30px;
            }

            &-link {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;

                @media screen and (max-width: 991px) {
                    margin-top: 10px;
                }
            }

            &-cards {
                background-color: $color-white;
                position: relative;
                border-radius: 20px;
                padding: 60px 30px;
                text-align: center;
                box-shadow: 1px 0px 7px #00000029;

                &-number {
                    position: absolute;
                    top: 25px;
                    right: 25px;
                    color: $color-green;
                    opacity: 0.55;
                    font-weight: bold;
                    font-size: 50px;
                    line-height: 59px;
                    font-family: $font-family;
                }

                &-img {
                    width: 140px;
                    height: 140px;
                    margin-bottom: 15px;
                }

                &-title {
                    color: $color-blue;
                    font-size: 24px;
                    line-height: 26px;
                    font-weight: bold;
                    text-align: center;
                }
            }

            @media (min-width: 992px) and (max-width: 1199px) {
                &-cards {
                    padding: 30px;

                    &-number {
                        top: 15px;
                        right: 15px;
                    }

                    &-title {
                        font-size: 19px;
                        line-height: 21px;
                    }
                }
            }

            @media screen and (max-width: 991px) {
                &-text {
                    font-size: 18px;
                    line-height: 23px;
                }

                &-link {
                    justify-content: flex-start;
                }

                &-cards {
                    margin-bottom: 30px;
                }
            }
        }

        &-explications {
            margin: 60px auto;
            padding: 3rem 0;

            &-content {
                @media screen and (max-width: 991px) {
                    margin-top: 20px;
                }

                &-title {
                    text-align: left;
                    font-size: 40px;
                    line-height: 45px;
                    font-weight: bold;
                    color: $color-blue;
                    margin-bottom: 30px;
                }

                &-block {
                    margin-bottom: 10px;

                    &-subtitle {
                        position: relative;
                        text-align: left;
                        font-weight: bold;
                        font-size: 22px;
                        line-height: 28px;
                        color: $color-green;
                        margin-bottom: 5px;
                        padding-left: 35px;
                    }

                    svg {
                        position: absolute;
                        top: 2px;
                        left: 0;
                    }

                    &-text {
                        text-align: left;
                        font-size: 16px;
                        line-height: 20px;
                        color: $color-green;
                        padding-left: 35px;
                    }
                }

                &-button {
                    margin-top: 30px;
                }
            }
        }

        &-environnement {
            background-image: url("../../images/home/background-environnement.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 20px;
            margin: 60px auto;
            padding: 6rem 0;
            color: $color-white;
            text-align: center;

            &-content {
                &-block {
                    margin-top: 20px;

                    &-title {
                        color: $color-white;
                        margin-bottom: 15px;
                    }

                    &-subtitle {
                        font-size: 24px;
                        line-height: 30px;
                        color: $color-white;
                    }
                }

                &-button {
                    margin-top: 50px;

                    .solu-btn-primary {
                        font-size: 18px;
                        line-height: 23px;
                        color: $color-white;
                        background-color: $color-blue;
                    }
                }
            }

            @media screen and (max-width: 767.98px) {
                &-content {
                    img {
                        width: 70%;
                    }

                    &-block {
                        &-subtitle {
                            font-size: 18px;
                            line-height: 23px;
                            color: $color-white;
                        }
                    }
                }
            }
        }

        &-partners {
            margin: 60px auto;
            padding: 3rem 0;
            text-align: center;

            &-text {
                font-size: 24px;
                line-height: 30px;
                color: $color-grey;
                margin-bottom: 59px;
            }

            .splide__arrow {
                background-color: transparent;
                color: $color-green;

                &.splide__arrow--prev {
                    left: -10px;
                }

                &.splide__arrow--next {
                    right: -10px;
                }

                svg {
                    height: 2.2em;
                    width: 2.2em;
                }
            }
        }
    }
}
