.account-details {
  padding: 70px 0;

  &-since {
    color: #B1B1B1;
    font-size: 14px;
    line-height: 18px;
  }

  &-formule {
    width: 40%;
    min-width: 320px;
    margin: 2rem auto 0;
    background-color: #EFFFF1;
    box-shadow: 1px 0px 7px #00000029;
    text-align: center;
    border-radius: 20px;
    padding: 20px;

    &-type {
      font-size: 20px;
      line-height: 23px;

      span {
        text-transform: capitalize;
        font-weight: bold;
      }
    }

    &-activity {
      font-size: 20px;
      line-height: 23px;
      color: $color-green;
    }
  }

  &-modal {
    .modal-content {
      border-radius: 30px;

      .modal-header {
        position: relative;
        text-align: center;
        align-items: flex-start;

        .modal-title {
          margin-top: 50px;
          width: 100%;
        }

        .btn-close {
          box-shadow: 1px 0px 7px #00000029;
          border-radius: 100%;
          margin: 10px;
          position: absolute;
          right: 10px;
        }
      }
    }
  }
}
