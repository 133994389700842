.worksite {
    &-form {
        &-type {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                padding: 10px 0;
            }

            &-input {
                border: 1px solid #B1B1B1;
                border-radius: 6px;
                padding: 2px 5px;
            }

            &-truck {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
                padding: 5px 0;
                width: 50%;

                &-text {
                    text-align: center;
                    color: $color-blue;
                    margin-top: 10px;
                    font-weight: bold;
                }

                img {
                    max-width: 110px;
                }
            }
        }

        &-description {
            margin-bottom: 40px;
        }
    }

    &-need {
        img {
            height: 40px;
        }

        &-block {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &-nb {
                color: $color-green;
                font-weight: bold;
                font-size: 18px;
                padding-top: 10px;
            }

            &-text {
                text-align: center;
            }
        }
    }
}

.worksiteCalendar {
    .rmdp-range {
        background-color: $color-green;

        span {
            width: 30px !important;
            height: 30px !important;
        }
    }

    .rmdp-range:not(.rmdp-disabled) span {
        color: white !important;
    }
}