@import "../_variables";

.rmdp-wrapper {
  width: 100% !important;
  border: 0;
  box-shadow: none !important;
  margin: 10px 0;
}

.rmdp-calendar,
.rmdp-day-picker,
.rmdp-day-picker > div,
.rmdp-week {
  width: 100%;
}

.rmdp-arrow-container {
  background-color: $color-green;
  mask-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 0 10px;

  &.disabled:hover {
    background: #41c850;
  }

  &:hover {
    background-color: #41c85094;
  }

  &.rmdp-left {
    mask-image: url(../../images/chevron-left-solid.svg);
    mask-repeat: no-repeat !important;
  }

  &.rmdp-right {
    mask-image: url(../../images/chevron-right-solid.svg);
    mask-repeat: no-repeat !important;
  }

  i {
    display: none;
  }
}

.rmdp-header-values {
  span {
    color: #000f48;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 10px;
  }
}

.rmdp-week-day {
  color: #8d8d8d !important;
}

.rmdp-week {
  margin: 5px 0px;
}

.rmdp-day span {
  height: 34px !important;
  position: relative !important;
  width: 34px !important;
  border-radius: 50% !important;
}

.rmdp-day.rmdp-today span {
  background-color: transparent !important;
}

.rmdp-day:not(.rmdp-disabled) span {
  color: #000f48 !important;
  font-weight: 500;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  border: 1px solid $color-green !important;
  color: $color-green !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  box-shadow: 0 0 3px #87ad92 !important;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: $color-green !important;
  border-radius: 50% !important;
  box-shadow: 0 0 3px #87ad92 !important;
  color: #fff !important;
}

.rmdp-month-picker,
.rmdp-year-picker {
  .rmdp-day span {
    height: 34px;
    width: 100%;
    border-radius: initial !important;
  }

  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover,
  .rmdp-day.rmdp-selected span:not(.highlight) {
    border-radius: initial !important;
  }
}
