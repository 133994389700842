@import "../_variables";

.solu-btn {
  padding: 10px 25px;
  border-radius: 100px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;

  &::after {
    content: url('../../images/fleche-right-white.svg');
    width: 17px;
    margin-left: 17px;
    transition: 0.3s;
  }

  &:hover {
      padding-right: 20px;
      text-decoration: none;

      &::after {
          margin-left: 22px;
      }
  }

  &-primary {
    color: $color-white;
    background-color: $color-green;

    &:hover {
      color: $color-white;
      background-color: $color-green;
    }
  }

  &-outline {
    padding: 10px 25px;
    border-radius: 100px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    text-decoration: none;
    color: $color-green;
    background-color: transparent;
    border: 1px solid $color-green;

    &:hover {
      color: $color-white;
      background-color: $color-green;
    }
  }

  &-sm {
    padding: 5px 15px 5px 20px;
    font-size: 16px;
    line-height: 19px;

    &::after {
      width: 13px;
      margin-left: 7px;
    }
    &:hover {
      padding-right: 10px;
      &::after {
          margin-left: 12px;
      }
    }
  }

  &-add::after {
    content: '+';
    font-size: 25px;
  }
}


.btn {
  border-radius: 100px;
  color: #fff;
  margin-bottom: 10px;
  padding: 10px 45px;
  font-size: 18px;
  line-height: 21px;
  background-color: #EEE;
  border: 1px solid #FFF;

  &.btn-primary {
      background-color: $color-green;
      border-color: $color-green;

      &:hover {
          color: $color-green;
          background-color: initial;
          border-color: initial;
      }
  }

  &.btn-secondary {
      background-color: $color-grey;
      border-color: $color-grey;

      &:hover {
          color: $color-grey;
          background-color: initial;
          border-color: initial;
      }
  }

  &.btn-danger {
      background-color: $color-red;
      border-color: $color-red;

      &:hover {
        color: $color-red;
        background-color: initial;
        border-color: initial;
      }
  }

  &.btn-outline-primary {
      color: $color-green;
      background-color: initial;
      border-color: $color-green;

      &:hover {
          color: $color-white;
          background-color: $color-green;
          border-color: $color-green;
      }
  }

  &.btn-outline-secondary {
      color: $color-grey;
      background-color: initial;
      border-color: $color-grey;

      &:hover {
          color: $color-white;
          background-color: $color-grey;
          border-color: $color-grey;
      }
  }

  &.btn-outline-danger {
      color: $color-red;
      background-color: initial;
      border: 2px solid $color-red;

      &:hover {
          color: $color-white;
          background-color: $color-red;
          border: 2px solid $color-red;
      }
  }

  &.btn-sm {
      padding: 5px 15px;
      font-size: 14px;
      line-height: 18px;
  }
}