@import "../_variables";

.search-result-page {
  margin-top: 100px;

  @include media-breakpoint-down(xl) {
    margin-top: 70px;

    &-menu-lateral {
      position: fixed;
      top: 0;
      width: 50%;
      min-width: 400px;
      z-index: 120;
      background-color: $color-white;
      height: 100vh;
      overflow-y: scroll;
      padding: 6rem 2rem 2rem;
      left: -100%;
      transition: left linear 0.3s;
      max-width: 100%;

      &-actif {
        left: 0;
        padding-bottom: 100px;
      }

      .btnClose {
        position: absolute;
        top: 50px;
        right: 20px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &-cardcontainer {
      .solu-btn-sm {
        padding: 5px 15px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        &::after { display: none; }
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 50px;
    &-menu-lateral {
      min-width: auto;
      max-width: 90%;
    }
  }

  @include media-breakpoint-down(md) {
    &-menu-lateral {
      width: 100%;
      max-width: none;
    }
  }

  &-bg-filters {
    display: none;
    position: absolute;
    top: 0;
    bottom: -10000px;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #4f4f4fa6;

    &-active {
      display: block;
    }
  }
}

.text-result {
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  font-family: $font-family;
  color: $color-blue;
}

.accordion-header,
.accordion-body,
.accordion-button {
  padding-left: 0;
}

.accordion::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #B1B1B1;
  margin: 0 0 1px 0;
}

.accordion-button {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: $color-blue;
}

.accordion-button:not(.collapsed) {
  color: $color-blue;
  background-color: $color-white;
  border-color: $color-grey-light;
  box-shadow: none;
}

.accordion-button::after {
  background-image: none;
  background-color: $color-green;
  -webkit-mask-image: url(../../images/chevron-up-solid.svg);
  mask-image: url(../../images/chevron-up-solid.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.btn-maps {
  font-size: 16px;
  line-height: 20px;
  color: $color-white;
  background: $color-green 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 24px;
  padding: 10px 25px;
  text-align: center;
}

.search-result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.title-filter {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: $color-blue;
}

.badge-filter {
  border-radius: 50%;
  color: $color-white;
  background-color: $color-green;
  font-family: $font-family;
  font-size: 21px;
  text-align: center;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}


@media screen and (max-width: 1199.98px) {
  .text-result {
    font-size: 38px;
    line-height: 43px;
  }
  .search-result {
    margin-bottom: 25px;
  }
  .title-filter {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 575.98px) {
  .text-result {
    font-size: 30px;
    line-height: 34px;
  }
  .search-result {
    margin-bottom: 20px;
  }
}

/*
 * Filtres à facette
 */
.filter-current-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  padding: 0;

  li {
    border: 1px solid $color-green;
    border-radius: 50px;
    padding: 7px 16px;
    display: inline-block;
    line-height: 16px;
    margin-bottom: 5px;
    background-color: #effff1;
  }

  &-cross {
    cursor: pointer;
    height: 10px;
    width: 10px;
    color: #484848;
    padding-left: 10px;
  }
}

.filter-checkbox {
  margin-bottom: 11px;

  label {
    cursor: pointer;
  }

  input[type="checkbox"] {
    position: relative;
    cursor: pointer;
    appearance: none;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 17px;
    border: 2px solid #b1b1b1;
    border-radius: 2px;

    &:checked {
      background-color: $color-green;
      border: 2px solid $color-green;

      &:before {
        display: var(--displaybefore, none);
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 3px;
        background: #fff;
        transform: translate(-50%, -50%);
      }

      &:after {
        display: var(--displayafter, block);
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 11px;
        height: 16px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
        margin-top: -3px;
      }
    }
  }

  &.child {
    padding-left: 42.5px;
  }
}

.filter-range {
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &-wrapper {
      position: relative;
      width: 40%;
    }

    hr {
      width: 10%;
      border-top: 2px solid #484848;
    }

    &-input {
      width: 100%;
      border-radius: 6px;
      padding: 0 8px;
      border: 1px solid #b1b1b1;

      &::-webkit-inner-spin-button {
        appearance: none;
      }

      &-unit {
        position: absolute;
        right: 5px;
        font-size: 14px;
        top: 50%;
        transform: translate(0, -50%);
        color: #777;
      }
    }
  }
}

.filter-removeall {
  cursor: pointer;
  color: #000f48;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
  display: block;
}

.input-range__slider {
  border: 1px solid #41c850 !important;
  background: #fff !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: -10px !important;
}

.input-range__track {
  height: 2px !important;
  background-color: #707070 !important;
}

.input-range__track--active {
  background-color: #41c850 !important;
}

/* DATE */
.filter-date-wrapper {
  position: relative;
}

.rdrCalendarWrapper {
  position: absolute;
  top: calc(100% - 10px);
  // left: 0;
  z-index: 9999;
  background: $color-white 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
}

.rdrMonthAndYearPickers select {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $color-blue;
}

.rdrWeekDays {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: #8d8d8d;
  text-transform: capitalize;
}

.rdrPprevButton {
  background-color: $color-green;
  -webkit-mask-image: url(../../images/chevron-left-solid.svg);
  mask-image: url(../../images/chevron-left-solid.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.rdrPprevButton i {
  display: none;
}

.rdrNextButton {
  background-color: $color-green;
  -webkit-mask-image: url(../../images/chevron-right-solid.svg);
  mask-image: url(../../images/chevron-right-solid.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.rdrNextButton i {
  display: none;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: $color-blue;
  font-weight: 600;
}

.rdrDayNumber span {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: $color-blue;
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  color: $color-blue;
  text-transform: capitalize;
}

.rdrDayToday .rdrDayNumber span:after {
  content: none;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrDayStartOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrInRange {
  border-radius: initial;
}

.rdrDayDisabled {
  opacity: 0.5;
}

.rdrDayPassive {
  opacity: 0;
}

.rdrStartEdge,
.rdrEndEdge {
  background: $color-green;
  position: absolute;
  top: 1px;
  left: 4px;
  right: 0;
  bottom: 5px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  opacity: 1;
}

.rdrStartEdge:not(.rdrEndEdge) ~ .rdrDayNumber:before {
  content: "";
  background: $color-green;
  position: absolute;
  top: 0;
  left: 70%;
  right: 0;
  bottom: 0;
  border-radius: initial;
  opacity: 0.22;
}
.rdrEndEdge:not(.rdrStartEdge) ~ .rdrDayNumber:before {
  content: "";
  background: $color-green;
  position: absolute;
  top: 0;
  left: 0;
  right: 70%;
  bottom: 0;
  border-radius: initial;
  opacity: 0.22;
}

.rdrSelected,
.rdrInRange {
  background: $color-green;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
  border-radius: initial;
  opacity: 0.22;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayInPreview {
  border-radius: initial;
  left: 0;
  right: 0;
  display: none;
}

.rdrDayStartPreview,
.rdrDayEndPreview {
  border-width: 1px;
  top: 1px;
  left: 4px;
  right: 0;
  bottom: 5px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

.rdrDayInPreview {
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
  border-radius: initial;
  display: none;
}

/* LOCALISATION */

.filter-localisation-wrapper {
  position: relative;
}

.localisation-card {
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  z-index: 9998;
  background: $color-white 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 24px;
  width: 100%;
  padding: 15px;

  &-city {
    border: 1px solid #484848;
    border-radius: 50px;
    padding: 5px 7px;
    display: inline-block;
    line-height: 16px;
    margin-bottom: 5px;
    font-size: 12px;
  }

  &-label {
    color: $color-blue;
    font-size: 16px;
    font-weight: bold;
  }

  .input-range {
    margin: 30px auto 40px;
  }

  .input-range__label--value .input-range__label-container {
    position: relative;
    background: $color-green;
    color: #fff;
    padding: 2px 35px 2px 10px;
    border-radius: 15px;

    &::after {
      position: absolute;
      right: 10px;
      content: "Km";
    }
  }

  .input-range__label--min {
    left: 5px;
  }

  .input-range__label--max {
    right: 35px;
  }

  .input-range__label--min .input-range__label-container,
  .input-range__label--max .input-range__label-container {
    position: relative;

    &::after {
      position: absolute;
      right: -25px;
      content: "Km";
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .localisation-card-button {
      cursor: pointer;
      font-size: 14px;
    }

    .erase {
      color: #b1b1b1;
    }

    .validate {
      color: $color-green;
    }
  }
}

.suggestions,
.no-suggestions {
  display: grid;
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 15px;
  /* list-style: none; */
  width: calc(100% - 20px);
  z-index: 10;
  box-shadow: 0 0.25rem 0.625rem rgba(86, 88, 89, 0.2);

  li {
    display: flex;
    align-items: center;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
    color: #2e3d44;
  }

  li:hover,
  .suggestion-active {
    color: #000;
    font-weight: bold;
    background: #f0f0f0;
    border-radius: 10px;
    cursor: pointer;
  }
}

.ads-need-connection {
  position: relative;

  &-container {
    position: absolute;
    z-index: 10;
    text-align: center;
    margin-top: 200px;
    width: 100%;

    p {
      color: $color-blue;
      font-size: 40px;
      line-height: 44px;
      font-weight: 600;
      margin-bottom: 50px;
    }

    .links {
      color: $color-blue;
      font-size: 18px;
      font-weight: 600;
      margin-left: 20px;
    }

    @include media-breakpoint-down(xl) {
      margin-top: 50px;
      position: initial;

      p {
        font-size: 30px;
        line-height: 34px;
      }
    }
  }
}
