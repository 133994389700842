.subcategory-card {
    font-size: 14px;
    padding: 0 !important;
    justify-content: space-between;
    margin-bottom: 1rem;
    text-align: center;

    &:hover {
        border: 2px solid rgb(26, 188, 156);
    }

    &-img {
        max-width: 100%;
        height: 146px;
        object-fit: cover;
    }

    &-link {
        text-decoration: none;
    }

    &-title {
        color: #000;
        font-size: 17px;
        text-align: center;
        padding: 15px;
    }
}