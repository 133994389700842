.profile {
    &-top {
        display: none;
        background-size: cover;
        padding: 50px 0;

        &-content {
            display: flex;
            justify-content: flex-start;

            @media screen and (max-width: 767.98px) {
                flex-direction: column;
            }

            &-image {
                width: 90px;
                height: 90px;
                border-radius: 100px;
                margin: auto 0;

                @media screen and (max-width: 767.98px) {
                    margin: 0 auto 10px;
                }
            }

            &-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 30px;

                &-name {
                    font-size: 40px;
                    color: $color-blue;
                    font-weight: bold;
                    margin-bottom: 0;
                    line-height: 40px;
                }

                &-description {
                    color: $color-blue;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 35px;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-verif {
        background-color: $color-green;
        padding: 5px 0;

        &-text {
            color: #fff;
            text-align: center;
            margin-bottom: 0;
        }
    }
}
