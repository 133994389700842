//MDWChris : modif sur Bootstrap CSS
$primary: #41C850;
$input-btn-padding-y:         .75rem;
$input-btn-padding-x:         .75rem;
$form-select-focus-border-color:  lighten(#41C850, 20);
$form-select-focus-box-shadow:    0 0 0 2px lighten(#41C850, 40);

$grid-gutter-width: 2.5rem;


@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');

// Packages
@import '~bootstrap/scss/bootstrap';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import './_global';

// Pages
@import './pages/home';
@import './pages/profile';
@import './pages/catalogue';
@import './pages/company';
@import './pages/contact';
@import './pages/discover';
@import './pages/product';
@import './pages/worksite';
@import './pages/results';
@import './pages/login';
@import './pages/register';
@import './pages/notfoundpage';

// Components
@import './components/Loader';
@import './components/Menu';
@import './components/Footer';
@import './components/Banner';
@import './components/SearchFilters';
@import './components/Breadcrumbs';
@import './components/cards/CategoryCard';
@import './components/cards/SubCategoryCard';
@import './components/cards/ProductList';
@import './components/cards/global';
@import './components/profile/Messagerie.scss';
@import './components/profile/AddTruck.scss';
@import './components/profile/ReservationRow.scss';
@import './components/profile/AccountDetails.scss';
@import './components/profile/Products.scss';
@import './components/profile/CreateProduct.scss';
@import './components/Promo.scss';
