.not-found {
    color: $color-blue;
    margin-top: 2em;

    &-title {
      font-size: 7.5em;
      margin: 15px 0px;
      font-weight: bold;
      color: $color-blue;
      line-height: 5.8rem;
    }

    &-subtitle {
      font-size: 2em;
      margin: 15px 0px;
      font-weight: bold;
    }

    &-text {
      font-size: 1.5em;
      margin: 15px 0px;
    }

    &-link {
      font-size: 1.5em;
      margin: 15px 0px;
      font-weight: bold;
    }
  }
