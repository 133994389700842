.promo {
    &-banner {
        &-top {
            width: 100%;
            position: relative;
            background-color: #ed5252;
            text-align: center;
            padding: 10px 20px;

            @media screen and (max-width: 768px) {
                padding: 5px 20px;
            }

            &-text {
                color: #fff;
                font-size: 24px;
                letter-spacing: 4px;
                font-weight: bold;
                margin-bottom: 0;

                @media screen and (max-width: 1200px) {
                    font-size: 15px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 11px;
                    letter-spacing: 1px;
                }
            }
        }

        &-content {
            width: 100%;
            position: relative;
            background-color: #ed5252;
            text-align: center;
            padding: 30px 20px;
            margin: 50px 0;

            @media screen and (max-width: 768px) {
                padding: 10px 20px;
            }

            &-text {
                color: #fff;
                font-size: 24px;
                letter-spacing: 2px;
                font-weight: bold;
                margin-bottom: 0;

                @media screen and (max-width: 1200px) {
                    font-size: 15px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    letter-spacing: 1px;
                }
            }

            &-button {
                color: #fff;
                padding: 10px 25px;
                border-radius: 100px;
                font-size: 18px;
                line-height: 23px;
                font-weight: 500;
                text-decoration: none;
                transition: 0.3s;
                display: inline-flex;
                align-items: center;
                border: 1px solid #fff;
                margin-top: 20px;

                @media screen and (max-width: 1200px) {
                    font-size: 15px;
                }

                @media screen and (max-width: 768px) {
                    padding: 5px 15px;
                    font-size: 12px;
                }

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
