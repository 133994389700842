.register {
    &-form {
        height: 100vh;

        h2 {
            margin-bottom: 10px;
        }

        &:before {
            content: '';
            position: fixed;
            top: 40vh;
            right: 86vw;
            width: 664px;
            height: 861px;
            background: center center no-repeat url('../../images/spot-rayures.png');
            background-size: cover;
            z-index: -1;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &-content {
            padding: 30px 0 60px;

            .row {
                margin-right: 0;
                margin-left: 0;
            }

            &-text {
                font-size: 20px;
            }

            &-profile {
                display: flex;
                justify-content: center;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }
            }

            &-items {
                padding-bottom: 200px;

                @include media-breakpoint-down(md) {
                    margin-right: 1.25rem;
                    margin-left: 1.25rem;
                }

                &-warningblock {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 20px;

                    img {
                        width: 24px;
                        margin-right: 10px;
                    }

                    p {
                        margin-bottom: 0 !important;
                        color: red;
                    }
                }
            }
        }

        .type-block {
            position: relative;
            width: 300px;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-shadow: 1px 0px 7px #00000029;
            border-radius: 10px;
            cursor: pointer;
            padding: 20px 2vw;
            margin: 20px;
            border: 2px solid transparent;

            @include media-breakpoint-down(xl) {
                height: 230px;

                img {
                    max-width: 150px;
                }
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                height: 250px;
            }

            @include media-breakpoint-down(sm) {
                padding: 0px 2vw;
                height: 180px;

                img {
                    max-width: 100%;
                }
            }

            &.selected {
                margin: 20px;
                border: 2px solid $color-green;

                @include media-breakpoint-down(md) {
                    margin: 10px;
                }
            }

            &-text-free {
                border: 2px solid $color-green;
                border-radius: 30px;
                font-size: 16px;
                margin: 0 40px;
                padding: 5px 40px;
                color: $color-green;
                background-color: #fff;
                position: absolute;
                top: 10px;
            }

            &-text {
                border: 2px solid #fff;
                border-radius: 30px;
                font-size: 16px;
                padding: 5px 40px;
                color: #fff;
                background-color: $color-green;
                text-align: center;
                position: absolute;
                top: 10px;
            }

            &-price {
                padding: 10px 25px;
                border-radius: 100px;
                font-size: 18px;
                line-height: 23px;
                font-weight: 500;
                text-decoration: none;
                transition: 0.3s;
                display: inline-flex;
                align-items: center;
                color: $color-white;
                background-color: $color-green;

                &:hover {
                    color: $color-white;
                    background-color: $color-green;
                    padding-right: 20px;
                    text-decoration: none;
                }

                &.free {
                    padding: 10px 25px;
                    border-radius: 100px;
                    font-size: 18px;
                    line-height: 23px;
                    font-weight: 500;
                    text-decoration: none;
                    color: $color-green;
                    background-color: transparent;
                    border: 1px solid $color-green;

                    &:hover {
                      color: $color-white;
                      background-color: $color-green;
                    }
                }
            }

            &-title {
                color: $color-blue;
                text-align: center;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;

                @include media-breakpoint-down(xl) {
                    font-size: 14px;
                }

                @include media-breakpoint-down(sm) {
                    line-height: 17px;
                }

                &.green {
                    color: $color-green;
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-down(md) {
                padding: 20px 4vw;
                margin: 10px;
            }
        }

        &-field {
            position: relative;
            display: flex;
            flex-direction: column;

            &-password {
                input {
                    width: 80%;
                }

                button {
                    background: transparent;
                    border: 0;
                }
            }

            label {
                color: #000F48;
                font-weight: bold;
            }

            input {
                border: 1px solid #B1B1B1;
                border-radius: 6px;
                margin-top: 5px;
                margin-bottom: 15px;
                height: 35px;
                padding: 0 10px;
            }

            .error {
                width: 100%;
                margin-top: -0.75rem;
                font-size: 0.875em;
                color: #dc3545;
            }

            &-image {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 20px 0;

                // button {
                //     display: block;
                //     width: 135px;
                //     height: 135px;
                //     background-color: initial;
                //     border: dashed 2px $color-green;
                //     border-radius: 10px;
                // }

                p {
                    font-size: 14px;
                    color: #707070;
                    margin-top: 10px;
                }

                .image-item {
                    width: 135px;
                    height: 135px;

                    img {
                        object-fit: contain;
                        height: 100%;
                        width: 100%;
                    }

                    &__btn-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        column-gap: 10px;
                    }
                }
            }

            &-location {
                display: flex;
                flex-direction: column;

                label {
                    color: #000F48;
                    font-weight: bold;
                }

                span {
                    font-size: 14px;
                    color: #707070;
                    margin-bottom: 20px;
                }

                input {
                    width: 60%;
                    height: 52px;
                    margin-left: 1px;
                    margin-bottom: 20px;
                    border: 1px solid #B1B1B1;
                    border-radius: 10px;
                    padding: 0 10px;
                }
            }

            &-nbvehicles {
                input {
                    padding: 0 10px;
                    max-width: 15%;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }
            }

            &-checkbox {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 50px;
                text-align: start;

                label {
                    margin-left: 20px;
                    display: block;

                    a {
                        color: $color-green;
                        text-decoration: none;
                    }
                }

                input {
                    position: relative;
                    cursor: pointer;
                    appearance: none;
                    width: 25px;
                    min-width: 25px;
                    height: 25px;
                    vertical-align: middle;
                    border: 2px solid #b1b1b1;
                    border-radius: 2px;

                    @include media-breakpoint-down(lg) {
                        width: 45px;
                    }

                    &:checked {
                        background-color: $color-green;
                        border: 2px solid $color-green;

                        &:before {
                          display: block;
                          content: "";
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          width: 11px;
                          height: 16px;
                          border: solid #ffffff;
                          border-width: 0 2px 2px 0;
                          transform: translate(-50%, -50%) rotate(45deg);
                          margin-top: -3px;
                        }
                    }
                }
            }
        }
    }

    &-price {
        &-presentation {
          &-subtitle {
            font-size: 24px;
            line-height: 30px;
            font-weight: 500;
            color: $color-grey;
          }
        }

        &-cards {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          background: $color-white;
          box-shadow: 2px 2px 8px #00000029;
          border: 3px solid transparent;
          border-radius: 20px;
          text-align: center;
          margin-bottom: 30px;
          padding: 0 20px;

          strike {
            font-size: 19px;
            color: $color-green;
          }

          &-active {
            background: #effff1;
            box-shadow: 2px 2px 8px #00000029;
            border: 3px solid $color-green;
            border-radius: 20px;
            text-align: center;
          }

          &-number {
            color: $color-green;
            font-size: 19px;
            line-height: 29px;
            padding: 15px 0 0;

            &-special {
                position: relative;
                display: inline-block;
                text-decoration: none;
                font-size: 34px;
                line-height: 29px;
                font-weight: 800;
            }
          }

          &-title {
            margin: 0 auto !important;
            padding-top: 15px;
          }

          &-separator {
            border: 1px solid $color-grey-light;
            margin: 20px 30px;
            opacity: 1;

            &-active {
              border: 1px solid $color-green;
              margin: 20px 30px;
              opacity: 1;
            }
          }

          &-button {
            margin-top: 35px;
            margin-bottom: 35px;
          }

          &-list {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 18px;
            line-height: 23px;
            color: #2e3d44;

            &-item {
              margin: 10px 0;
              font-size: 15px;
            }
          }
        }
      }

    &-container-allcenter {
        margin-top: 3vw;
        text-align: center;

        .notcentered {
            text-align: left;
        }
    }

    &-final {
        margin-top: 3vw;
        text-align: center;

        h2 {font-size: 60px;}
        p {font-size: 24px; font-weight: 500; margin: 2vw 0;}
        span {color: $color-green;}
    }

    &-progress {
        &-mobile {
            background-color: #fff;
            padding: 10px 0 0;

            ul {
                display: flex;
                justify-content: center;
                margin: 0;
                padding: 0;

                li {
                    background-color: #B1B1B1;
                    width: 30px;
                    height: 6px;
                    border-radius: 6px;
                    list-style-type: none;
                    margin: 0 5px;

                    &.active {
                        background-color: $color-green;
                    }
                }
            }
        }

        &-container {
            z-index: 150;
            position: relative;
            height: 10px;
            background: $color-grey-light;

            ul li {
              position: absolute;
              top: -3px;
              left: 0;
              margin: 0;
              padding: 0;
              display: inline-table;
              height: 15px;
              width: 15px;
              background: $color-white;
              border-radius: 50%;
              box-shadow: 0 0 0 2px $color-green;

              &.stepwithtruck::before {
                content: url(../../images/register/PICTO_CAMION_ETAPE_INSCRIPTION.svg);
                position: absolute;
                top: -70px;
                right: -33px;
                width: 130px;
                height: 60px;
              }

              &.three-steps {
                  &:nth-child(1) {
                    margin-left: 16vw;
                  }
                  &:nth-child(2) {
                    margin-left: 39.5vw;
                  }
                  &:nth-child(3) {
                    margin-left: 60vw;
                  }
                  &:nth-child(4) {
                    margin-left: 80vw;
                  }
                  &:nth-child(5) {
                    margin-left: 96vw;
                  }
              }

              &.five-steps {
                  &:nth-child(1) {
                    margin-left: 16vw;
                  }
                  &:nth-child(2) {
                    margin-left: 33vw;
                  }
                  &:nth-child(3) {
                    margin-left: 50vw;
                  }
                  &:nth-child(4) {
                    margin-left: 66vw;
                  }
                  &:nth-child(5) {
                    margin-left: 83vw;
                  }
                  &:nth-child(6) {
                    margin-left: 96vw;
                  }
              }
            }
        }

        &-barre {
            height: 10px;
        }
    }

    &-button {
        display: flex;
        justify-content: center;
        padding: 30px 0 40px;
        background-color: #fff;

        &-promo {
            border: 2px solid $color-green;
            border-radius: 100px;
            text-align: center;
            font-size: 18px;
            line-height: 21px;
            font-family: $font-family;
            font-weight: bold;
            color: $color-green;
            padding: 10px 24px;
            text-decoration: none;

            &:hover {
                color: #fff;
                background-color: $color-green;
            }

            &-selected {
                border: 2px solid $color-green;
                border-radius: 100px;
                text-align: center;
                font-size: 18px;
                line-height: 21px;
                font-family: $font-family;
                font-weight: bold;
                color: #fff;
                padding: 10px 24px;
                text-decoration: none;
                background-color: $color-green;

                &:hover {
                    color: $color-green;
                    background-color: #fff;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 20px 0 40px;
        }

        &-next {
            background-color: $color-green;
            padding: 5px 40px;
            border-radius: 30px;
            font-size: 18px;
            border: 2px solid $color-green;
            color: #fff;
            margin: 0 40px;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                margin: 0 20px;
            }

            &:disabled {
                background-color: #dfdfdf;
                color: #fff;
                border: 2px solid #dfdfdf;
                cursor: default;

                &:hover {
                    background-color: #dfdfdf;
                    color: #fff;
                    border: 2px solid #dfdfdf;
                }
            }

            &:hover {
                background-color: #fff;
                color: $color-green;
            }
        }

        &-back {
            background-color: #fff;
            padding: 5px 40px;
            border-radius: 30px;
            font-size: 18px;
            border: 2px solid $color-green;
            color: $color-green;
            margin: 0 40px;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                margin: 0 20px;
            }

            &:hover {
                background-color: $color-green;
                color: #fff;
            }
        }
    }

    &-footer {
        position: fixed;
        width: 100vw;
        bottom: 0;
        left: 0;
    }
}