@import "../../_variables";

.search-result-page-cardcontainer {
  height: 100%;
}

.product-other-offers .card-product {
  height: auto !important;
}
.card-product {
  text-align: left;
  background: $color-white 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 8px #00000029 !important;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 2px solid transparent !important;
  height: calc(100% - 20px);
  cursor: pointer;

  &:hover {
    border: 2px solid $color-green !important;
  }

  &-pic {
    border-radius: 10px;
    width: 100%;
    height: 90%;
    max-height: 25vh;
    object-fit: cover;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;

      @include media-breakpoint-down(xl) {
        height: 200px;
      }
    }
  }

  &-body {
    padding-top: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: $color-blue;
  }

  &-category {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $color-blue;

    span {
      white-space: nowrap;
    }
  }

  &-localisation {
    font-size: 16px;
    line-height: 20px;
    color: $color-grey;

    svg {
      width: 14px;
    }
  }

  &-presentation {
    font-size: 14px;
    line-height: 18px;
    color: $color-blue;
    margin-bottom: 0;
  }

  &-price {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: $color-blue;
  }

  &-separation {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .btn {
      margin: 0 5px;
      font-size: 16px;
    }
  }

  &-unpublished {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;

    &:before {
      content:'';
      background-color: $color-grey-light;
      opacity: 0.8;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .btn {
      margin: 20px 0;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: row;
    padding: .5rem !important;

    &-pic {
      width: 150px;
      height: 150px;
    }

    &-body {
      padding-top: 0;
      padding-left: 15px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}