.profile {
    &-content {
        margin: 70px 0;

        .profile-content-sticky {
          position: sticky;
          top: 2rem;
        }

        &-menu-lateral {
            position: fixed;
            top: 0;
            width: 20%;
            min-width: 400px;
            z-index: 120;
            background-color: $color-white;
            height: 100vh;
            overflow-y: scroll;
            padding: 6rem 2rem 2rem 2rem;
            right: -5000px;
            transition: all 0.5s ease-in-out;

            @include media-breakpoint-down(sm) {
                width: 100%;
                min-width: 100%;
            }

            &-actif {
                right: 0;
            }

            .btnClose {
                position: absolute;
                top: 50px;
                right: 20px;
            }

            &-bg-filters {
                display: none;
                position: absolute;
                top: 0;
                bottom: -10000px;
                left: 0;
                right: 0;
                z-index: 100;
                background-color: #4f4f4fa6;
                cursor: pointer;

                &-actif {
                    display: block;
                }
            }
        }

        &-block-add-button {
            display: flex;
            justify-content: center;
        }

        .profile-content-sticky {
            position: initial;
            top: 0;
        }

        &-welcome {
            text-align: center;
            margin-bottom: 2rem;

            p {
                margin-bottom: 2px;
            }

            .profil {
                svg {
                    color: $color-blue;
                    border: solid 4px $color-white;
                    border-radius: 100px;
                    font-size: 50px;
                }
            }
            .message-1 {
                font-size: 25px;
                font-weight: 700;
                color: $color-blue;
            }
            .message-2 {
                font-size: 18px;
                font-weight: 500;
                color: $color-grey;
            }
        }

        &-add-button {
            background-color: $color-green;
            border-radius: 20px;
            color: #fff;
            border: 2px solid $color-green;
            padding: 5px 30px;
            font-size: 18px;
            text-decoration: none;

            &:hover {
                background-color: #fff;
                color: $color-green;

                .nav-link {
                    color: $color-green;
                }
            }

            .nav-link {
                color: #fff;
            }
        }
    }
    &-nav-tabs {
        --bs-nav-tabs-border-width: 0;

        .nav-item {
            .nav-link {
                &.active {
                    color: $color-green;
                    background-color: #EFFFF1;
                    border-radius: 50px;
                }

                border: none !important;
                font-size: 18px;
                color: $color-blue;
            }
        }

        &-counter {
            display: inline-block;
            background-color: red;
            width: 17px;
            height: 17px;
            border-radius: 20px;
            position: relative;
            margin-left: 5px;

            span {
                position: absolute;
                color: #fff;
                font-size: 14px;
                left: 5px;
                top: -2px;
            }
        }

        &-counter-double {
            display: inline-block;
            background-color: red;
            width: 22px;
            height: 22px;
            border-radius: 20px;
            position: relative;
            margin-left: 5px;

            span {
                position: absolute;
                color: #fff;
                font-size: 14px;
                left: 5px;
                top: 0;
            }
        }
    }

    &-menu {
        border-radius: 10px;
        margin-top: 50px;

        &-item {
            border: 2px solid #B1B1B1;
            padding: 15px;

            @include media-breakpoint-down(sm) {
                justify-content: center;
            }

            &:last-child {
                background-color: #ededed !important;
            }

            &:not(:first-child) {
                cursor: pointer;
            }

            &-message {
                &-count {
                    background-color: red;
                    color: #fff;
                    font-size: 11px;
                    margin-left: 10px;
                    padding: 0 3px;
                    border-radius: 100%;
                }
            }
        }

        &-top {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-down(sm) {
                align-items: center;
            }

            &-validation {
                color: $color-green;
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        &light {
            margin-top: 20px;
            margin-bottom: 40px;

            &-item {
                border: 0;
                cursor: pointer;

                @media screen and (max-width: 576px) {
                    justify-content: center;
                }

                &-active {
                    background-color: #EFFFF1 !important;
                    border-left: 4px solid $color-green;
                    border-top-left-radius: 0 !important;
                    border-left: 4px solid $color-green;
                    border-bottom-left-radius: 0 !important;

                    .profile-menulight-item-button {
                        color: $color-green;
                        margin-left: -4px;
                    }

                    @include media-breakpoint-down(sm) {
                        background-color: transparent !important;
                        border-left: 0px;
                    }
                }

                &-button {
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 500;
                    text-decoration: none;
                    color: #484848;

                    &-nbmessages {
                        display: inline-block;
                        text-align: center;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 20px;
                        color: #fff;
                        border-radius: 100%;
                        background-color: red;
                        height: 20px;
                        width: 20px;
                    }
                }
            }

        }

        &-button {
            color: $color-blue;
            text-decoration: none;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

.nav-link {
    color: $color-blue;
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    position: relative;

    &:hover {
        color: $color-blue;
    }

    &-cta {
        display: flex;
        align-items: center;
        color: $color-white;
        background-color: $color-green;
        font-weight: 500;
        border-radius: 100px;
        padding: 10px 25px;
        transition: 0.3s;

        &::after {
            content: url('../../images/fleche-right-white.svg');
            width: 17px;
            margin-left: 17px;
            transition: 0.3s;
        }

        &:hover {
            color: $color-white;
            padding-right: 20px;

            &::after {
                margin-left: 22px;
            }
        }
    }

    &-search {
        background-color: $color-green;
        border-radius: 30px;

        @include media-breakpoint-down(lg) {
            display: none;
        }
        
        .nav-link {
            padding: 10px 30px;
            color: #fff;
        }

        .dropdown-menu {
            margin-left: -70px;
            padding: 10px 15px;
            box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.1607843137);
            border-radius: 20px;

            .dropdown-item {
                padding: 5px 15px;
                width: 200px;
                margin: 0 10px;

                &:nth-child(1) {
                    border-bottom: 1px solid grey;
                }
            }
        }
    }

    &-social {
        color: #fff;
        background-color: $color-green;
        width: 30px;
        height: 30px;
        margin-right: 1rem;
        text-align: center;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
            width: 26px;
            height: 26px;
            font-size: 14px;
        }

        &:hover {
            color: $color-white;
        }
    }

    &-notif {
        color: $color-green;
        background-color: #EFFFF1;
        border: solid 2px #EFFFF1;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        position: relative;

        @include media-breakpoint-down(sm) {
            font-size: 19px;
        }

        .dropdown-menu {
            left: auto;
            right: 0;

            span {
                padding: 10px;
            }
        }

        .dropdown-toggle {
            color: $color-green;
            padding: 10px;

            &:after {
                display: none;
            }
        }

        &-nbmessages {
            position: absolute;
            top: 0;
            right: -10px;
            background-color: red;
            color: #fff;
            font-size: 11px;
            margin-left: 10px;
            padding: 0 3px;
            border-radius: 100%;
            display: inline-block;
            text-align: center;
            line-height: 20px;
            height: 20px;
            width: 20px;
        }

        &:hover {
            color: $color-green;
            background-color: #fff;
            border: solid 2px $color-green;
        }
    }
}

.app-navbar {
    background-color: $color-white;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: env(safe-area-inset-top) 0 0 0;

    .container-fluid {
        padding: 0 2rem;

        @include media-breakpoint-down(sm) {
            padding: 0 1rem;
        }
    }

    .navbar-toggler {
        border: none;
        box-shadow: none;
        padding: 0;
        margin-right: 1rem;

        &-icon {
            background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27><path stroke=%27rgba%280, 15, 72, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/></svg>');
        }
    }

    .spacerMenuForMobile {
        @media screen and (min-width: 768px) {
            display: none;
        }
        @include media-breakpoint-down(md) {
            flex-grow: 1;
        }
    }

    .navbar-brand {
        img {
            padding: 10px 0;
        }

        @include media-breakpoint-down(md) {
            img {
                width: 30px;
                height: 50px;
                object-fit: contain;
                padding: 0;
            }
        }
    }

    .navbar-collapse {
        justify-content: flex-end;
        align-items: center;

        @include media-breakpoint-down(md) {
            background-color: $color-white;
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
        }
    }

    .navbar-nav {
        align-items: center;
        column-gap: 3rem;

        .dropdown-menu {
            position: absolute;
            left: auto;
            right: 0;
        }

        @include media-breakpoint-down(xxl) {
            column-gap: 2rem;
        }

        @include media-breakpoint-down(lg) {
            column-gap: 1rem;
        }
    }

    .nav-menu {
        margin-right: 3rem;

        .nav-link {
            padding-left: 0;
            padding-right: 0;

            @include media-breakpoint-down(lg) {
                font-size: 14px;
            }

            &.active,
            &:hover {
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $color-green;
                }
            }
        }

        @media screen and (max-width: 1200px) {
            flex-grow: 1;
            margin-left: 1rem;
            margin-right: 0;
        }

        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }

    &-user {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        cursor: pointer;
        border: 1px solid $color-green;
        border-radius: 22px;
        
        span {
            color: $color-green;
            padding: 0 25px 2px 15px;
            font-size: 18px;

            @include media-breakpoint-down(lg) {
                font-size: 13px;
                padding: 0 15px 2px 10px;
            }
        }

        &.dropdown {
            margin-top: 20px;

            @include media-breakpoint-down(lg) {
                margin-top: 0;
            }

            &::after {
                content: "";
                border-top: 0;
                border-right: 0;
                border-bottom: 0;
                border-left: 0;
            }
        }

        svg {
            position: relative;
            margin: 3px;
            border-radius: 100%;
            font-size: 40px;
        }

        @include media-breakpoint-down(lg) {
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 25px;
                height: 25px;
                font-size: initial;
            }
        }

        .nav-link {
            &::after {
                color: $color-green;
                font-size: 26px;
            }

            &:hover {
                border-bottom: none !important;
            }
        }
    }
}


.app-navbar-secondary {
    background-color: $color-green;
    height: 40px;

    .navbar-secondary {
        display: flex;

        .nav-link {
            &-cta {
                display: flex;
                align-items: center;
                color: $color-green;
                background-color: $color-white;
                font-size: 14px;
                line-height: 18px;
                padding: 6px 20px;

                &::after {
                    content: url('../../images/fleche-right-green.svg');
                    width: 12px;
                    margin-left: 17px;
                    transition: 0.3s;
                }

                &:hover {
                    padding-right: 15px;

                    &::after {
                        margin-left: 22px;
                    }

                }
            }

            &-support {
                display: flex;
                align-items: center;
                color: $color-green;
                background-color: $color-white;
                font-size: 14px;
                line-height: 18px;
                padding: 6px 20px;
                border-radius: 20px;
                transition: 0.3s;

                &::after {
                    content: url('../../images/fleche-right-green.svg');
                    width: 12px;
                    margin-left: 17px;
                    transition: 0.3s;
                }

                &:hover {
                    padding: 6px 15px 6px 20px;

                    &::after {
                        margin-left: 22px;
                    }
                }
            }

            &-social {
                color: $color-green;
                background-color: $color-white;
                border: none;
                line-height: 27px;

                &:hover {
                    background-color: darken($color-white, 5%);
                }
            }
        }
    }
}