.messagerie {
    padding: 70px 0;
    overflow: hidden;

    &-conversation {
        min-height: 100vh;
        border-radius: 20px;
        padding: 20px 10px;
        background-color: #f8f8f8;

        h3 {
            color: $color-blue;
            padding: 0 20px;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            @media screen and (max-width : 1199.98px){
                text-align: center;
            }
        }

        &-listing {
            margin-top: 20px;
            height: 100%;
            overflow-y: auto;

            &-item {
                background: $color-white;
                border-radius: 20px;
                padding: 20px;
                margin: 0 10px 20px 10px;
                position: relative;
                display: flex;

                &:hover {
                    cursor: pointer;
                }

                &.active {
                    box-shadow: 1px 1px 8px #00000029;
                }

                .status {
                    position: absolute;
                    background-color: $color-green;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    right: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                }

                .date {
                    position: absolute;
                    top: 25px;
                    right: 20px;
                    color: #707070;
                    font-size: 12px;
                    line-height: 15px;

                    @include media-breakpoint-between(md, xl) {
                        position: inherit;
                    }
                }

                .profil {
                    margin-right: 1rem;
                    max-width: 75px;

                    svg {
                        border: solid 2px $color-white;
                        //outline: solid 2px $color-green; /* A cause de SAFARI */
                        box-shadow: 0 0 0 1px $color-green;
                        border-radius: 100%;
                        font-size: 50px;
                    }
                }

                .content {
                    p {
                        margin-bottom: 5px;
                    }

                    p:last-child,
                    .btn {
                        margin-bottom: 0;
                    }

                    .company {
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        color: $color-blue;
                    }
                    .product {
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 700;
                        color: $color-grey;
                    }
                    .excerpt {
                        font-size: 12px;
                        line-height: 15px;
                        color: $color-grey;
                    }
                }
            }
        }
    }

    &-top-area-buttons {
        display: none;
        height: 42px;
        background-color: $color-green;
    }

    &-informations {
        min-height: 200px;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            padding: 10px;
        }

        .general {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            line-height: 23px;
            font-weight: 600;
            color: $color-blue;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: solid 1px $color-grey-light;

            &-company,
            &-buttons {
                display: flex;
                align-items: center;
            }

            .btn {
                margin-bottom: 0;
                margin-left: 15px;
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;

                &-company {
                    margin-bottom: 10px;
                }

                .btn:first-child {
                    margin-left: 0;
                }
            }
        }

        .company {
            &_icon {
                svg {
                    border: solid 2px $color-white;
                    //outline: solid 2px $color-green; /* A cause de SAFARI */
                    box-shadow: 0 0 0 1px $color-green;
                    border-radius: 100px;
                    font-size: 50px;
                }

                &_logo {
                    width: 70px;
                    border-radius: 100%;
                    max-height: 50px;
                }
            }

            &_name {
                margin-left: 10px;
                color: $color-blue;
                text-decoration: none;
            }
        }

        .details {
            color: $color-blue;
            display: flex;

            &-bloc {
                &:last-child {
                    flex: 1 0;
                }

                &-name {
                    font-size: 30px;
                    color: $color-blue;
                    text-decoration: none;
                }
            }

            img {
                width: 90px;
                height: 90px;
                margin-right: 20px;
                border-radius: 5px;
            }

            p {
                margin-bottom: 0;
                &:nth-of-type(1) {
                    font-size: 30px;
                    line-height: 38px;
                    font-weight: 500;
                }
                &:nth-of-type(2) {
                    font-size: 14px;
                    line-height: 18px;
                }
                &:nth-of-type(3) {
                    font-size: 22px;
                    line-height: 28px;
                }
            }

            @include media-breakpoint-down(lg) {
                p {
                    &:nth-of-type(1) {
                        font-size: 25px;
                        line-height: 31px;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                img {
                    width: 70px;
                    height: 70px;
                }
                p {
                    &:nth-of-type(1) {
                        a {
                            font-size: 19px;
                            line-height: 24px;
                            color: $color-blue;
                            text-decoration: none;
                        }
                    }
                    &:nth-of-type(2) {
                        font-size: 11px;
                        line-height: 14px;
                    }
                    &:nth-of-type(3) {
                        font-size: 18px;
                        line-height: 23px;
                    }
                }
            }
        }
    }
}

.react-chat {
    &-container,
    &-row,
    &-viewerBox {
        height: calc(100vh - 200px - 20px);
    }

    &-viewerBox {
        border-radius: 20px;
        border-color: $color-green-light !important;
    }

    &-messageBox {
        margin-bottom: 1rem;
    }

    &-message-buttonGroupLeft, 
    &-message-buttonGroupRight {
        display: flex;
        justify-content: center;
        margin-top: 5px;

        .message-button-accept {
            border-radius: 100%;
            color: $color-green;
            background-color: #effff1;
            border: 0;
            font-size: 22px;
            padding: 5px 10px;
            margin-right: 10px;
        }
        .message-button-decline {
            border-radius: 100%;
            color: red;
            background-color: #FEE8E8;
            border: 0;
            font-size: 22px;
            padding: 5px 13px;
        }
    }

    &-avatar {
        border: solid 2px $color-white;
        //outline: solid 2px $color-green; /* A cause de SAFARI */
        box-shadow: 0 0 0 2px $color-green;
    }

    &-additional {
        color: #b1b1b1;
    }

    &-leftBubble {
        background-color: transparent;
        border-color: $color-green;
        color: $color-blue;
        font-size: 14px;
    }
    
    &-rightBubble {
        color: $color-blue;
        background-color: #EFFFF1;
        border: 0;
        font-size: 14px;
    }

    &-clearSelectedFiles {
        border-color: $color-red;
        color: $color-red;

        &:hover {
            border-color: $color-red;
            color: $color-white;
            background-color: $color-red;
        }
    }

    &-sendButton {
        @include media-breakpoint-down(md) {
            position: absolute;
            right: 10px;
            bottom: 0;
        }
    }

    &-inputBox {
        display: grid;
        grid-template-areas:
            "A B"
            "A C";
        grid-template-columns: 1fr auto;
        column-gap: 20px;

        @include media-breakpoint-down(md) {
            column-gap: 0;
        }

        .file-select {
            grid-area: B;

            @include media-breakpoint-down(md) {
                position: absolute;
                right: 10px;
                top: 5px;
            }
        }
        .react-chat-textarea {
            grid-area: A;
        }
        .react-chat-sendButton {
            grid-area: C;
        }
    }

    &-textarea {
        border: solid 1px #b1b1b1;
        border-radius: 10px;
        min-height: 100px;
        padding: 10px;
        margin-right: 10px;

        @include media-breakpoint-down(md) {
            padding: 10px 60px 10px 10px;
        }

        @media screen and (max-width : 1199.98px){
            border-radius: 10px;
        }
    }

    &-AttachmentIcon {
        color: $color-green;
        background-color: #effff1;
        font-weight: 500;
        font-size: 18px;
        padding: 5px 15px;
        border-radius: 100px;
        text-align: center;
    }

    &-SendIcon {
        color: $color-white;
        background-color: $color-green;
        font-size: 18px;
        padding: 5px 15px;
        border-radius: 100px;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
            background-color: transparent;
            color: $color-green;

            span {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .messagerie {
        padding: 20px 0;

        h2 {
            font-size: 30px;
            margin-left: 20px;
        }
    }

    .react-chat {
        &-container {
            display: none;
        }

        &-messagesListContent {
            justify-content: flex-end;
        }

        &-viewerBox {
            border: none;
        }

        &-messageBox {
            &:last-child {
                margin-bottom: 180px;
            }
        }

        &-inputBox {
            position: fixed;
            z-index: 10;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            box-shadow: 0px 0px 6px #00000029;
        }
    }

    .messagerie {
        &-informations,
        &-top-area-buttons {
            display: none;
        }

        &-message.active {
            .messagerie-informations,
            .messagerie-top-area-buttons {
                display: block;
            }

            .messagerie-informations {
                background-color: #fff;
                border-radius: 0 0 20px 20px;
            }

            .react-chat-container {
                display: block;
            }
        }

        &-conversation,
        &-message {
            position: relative;
            opacity: 0;
            transition: opacity 0.2s ease-out;
            display: none;

            &.active {
                animation: fadeIn 0.2s ease-out forwards;
                display: block;
            }
        }

        @keyframes fadeIn {
            0% {
                display: none;
                opacity: 0;
            }

            50% {
                display: block;
            }

            100% {
                opacity: 1;
                display: block;
            }
        }

        &-message,
        &-conversation {
            border-radius: initial;
            width: 100%;
        }

        &-conversation {
            padding: 10px 0 0;

            &-listing {
                height: initial;

                &-item {
                    margin: 10px;
                }
            }
        }
    }
}
