.login {
    &-top {
        padding-top: 90px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;
        margin-top: env(safe-area-inset-top);

        &-image {
            display: flex;
            justify-content: center;
        }

        &:before {
            content: '';
            position: fixed;
            top: 60vh;
            left: -150px;
            width: 664px;
            height: 861px;
            background: center center no-repeat url('../../images/spot-rayures.png');
            background-size: cover;
        }
    }

    &-title {
        font-size: 40px;
        line-height: 45px;
        color: $color-blue;
        margin-bottom: 15px;
    }

    &-text {
        color: #707070;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 20px;
        margin-top: -10px;
    }

    &-form {
        padding: 30px;
        background-color: #fff;
        border-radius: 30px;
        margin: calc(-50vh + 250px) auto 0;
        box-shadow: 0px 0px 6px #00000029;
        position: relative;
        z-index: 10;
        max-width: 576px;

        h3 {
            color: $color-blue;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
        }

        .btn {
            font-weight: 600;
        }

        &-lost-password {
            color: $color-green;
            text-decoration: underline;
            font-size: 14px;
            line-height: 26px;

            &:hover {
                text-decoration: none;
            }
        }

        .input-password {
            position: relative;

            &.fix-eye {
                .button-hide-password {
                    top: 35%;
                }
            }

            .form-control.is-invalid {
                background-image: none;
            }

            .button-hide-password {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 6px;
                border: 0;
                color: #707070;
                background: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &-top {
            height: 120px;
            padding-top: 25px;

            &:before {
                content: none;
            }
        }

        &-title {
            font-size: 30px;
            line-height: 34px;
            text-align: center;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        &-form {
            border-radius: 0;
            box-shadow: none;
            margin-top: 0;

            form {
                position: relative;
            }

            h3 {
                text-align: center;
            }

            .bloc-inscription {
                padding-top: 4rem;

                &:before {
                    content: '';
                    position: fixed;
                    top: 80vh;
                    left: -130px;
                    width: 332px;
                    height: 430px;
                    background: center center no-repeat url('../../images/spot-rayures.png');
                    background-size: cover;
                }

                .btn.btn-outline-primary {
                    background-color: $color-white;
                    position: relative;
                    z-index: 11;

                    &:hover {
                        background-color: $color-green;
                    }
                }
            }
        }
    }
}
