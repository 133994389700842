@import "../_variables";

.catalogue {
  &-section {
    &-truck {
      background-color: #f5f5f5;
      border-radius: 20px;
      margin: 60px auto;
      padding: 3rem 0;

      &-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
        width: 100%;

        @include media-breakpoint-down(md) {
          justify-content: center;
        }
      }
    }
  }
}