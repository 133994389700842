.my-trucks {
  padding: 70px 0;

  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }

  &-head {
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    h2 {
      @include media-breakpoint-down(sm) {
        font-size: 22px !important;
      }
    }

    .solu-btn {
      margin-right: 1.5rem;
      margin-left: 1.5rem;

      @include media-breakpoint-down(sm) {
        margin: 0 0 30px 0;
      }
    }
  }
}
