@import "../_variables";

.btn-statut {
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    padding: 7px 14px;
    border-radius: 100px;
    color: #fff;
    background-color: #EEE;

    &-pending {
        color: $color-orange;
        background-color: transparent;
        padding: 0;
    }
    &-accepted {
        color: #010F48;
        background-color: #F0F3FF;
    }
    &-closed {
        color: $color-grey;
        background-color: $color-grey-light;
    }
    &-refused {
        color: $color-red;
        background-color: $color-red-light;
    }
    &-inprogress {
        color: $color-green;
        background-color: #EFFFF1;
    }
    &-finish {
        color: #707070;
        background-color: #F5F5F5;
    }
}
