// Body
$color-blue: #000f48;
$color-green: #41c850;
$color-green-light: #d4f3d8;
$color-grey: #484848;
$color-grey-light: #ececec;
$color-red: #ed5252;
$color-red-light: #FEE8E8;
$color-white: #ffffff;

$color-orange: #D68910;
$color-orange-light: #FEF5E7;

// Typography
$font-family: 'Outfit', sans-serif;

// Tailles max pour le big container
$bigcontainer-maxwidth-xxl: calc(100% - 5rem);
$bigcontainer-maxwidth-xl: calc(100% - 5rem);
$bigcontainer-maxwidth-lg: calc(100% - 2.5rem);
$bigcontainer-maxwidth-md: calc(100% - 2.5rem);
$bigcontainer-maxwidth-sm: calc(100% - 1.5rem);
$bigcontainer-maxwidth-xs: 100%;

// Tailles max pour le medium container
$mediumcontainer-maxwidth-xxl: calc(100% - 30rem);
$mediumcontainer-maxwidth-xl: calc(100% - 15rem);
$mediumcontainer-maxwidth-lg: calc(100% - 2.5rem);
$mediumcontainer-maxwidth-md: calc(100% - 2.5rem);
$mediumcontainer-maxwidth-sm: calc(100% - 1.5rem);
$mediumcontainer-maxwidth-xs: 100%;

/*
 * MEMO
 * min-width: 1499.98px --> xxl
 * min-width: 1199.98px --> xl
 * min-width: 991.98px --> lg
 * min-width: 767.98px --> md
 * min-width: 575.98px --> sm
 * max-width: 575.98px --> xs
 **/