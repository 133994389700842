.contact {
  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 55px;
    line-height: 62px;
    color: $color-blue;
    margin-top: 100px;

    &-colored {
      text-align: center;
      font-weight: 500;
      color: $color-green;
    }
  }

  &-section-form {
    background: #f5f5f5;
    border-radius: 20px;
    margin: 60px 0;
    padding: 3rem 0;

    &-content {
      &-title {
        font-weight: bold;
        font-size: 40px;
        line-height: 45px;

        &-colored {
          color: $color-green;
        }
      }

      &-block {
        margin-top: 60px;

        &-subtitle {
          font-weight: bold;
          font-size: 22px;
          line-height: 28px;
          color: $color-blue;
        }

        &-text {
          font-size: 16px;
          line-height: 20px;
          color: $color-blue;

          &-social {
            color: $color-white;
            background-color: $color-green;
            width: 30px;
            height: 30px;
            border-radius: 100px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            &:hover {
              color: $color-white;
              background-color: darken($color-green, 10%);
            }
          }
        }
      }
    }

    .form-group > label {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: $color-blue;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  &-question {
    &-h2 {
      position: relative;
      font-size: 24px !important;
      margin-bottom: 30px !important;
      margin-top: 24px;
      border-bottom: 1px solid #B1B1B1;
      cursor: pointer;
      padding-bottom: 20px;
    }

    &-faq {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 60px;
      padding: 10px 3px;
      border: 0;
      border-bottom: 1px solid #B1B1B1;

      &:focus-visible {
        outline: none !important;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    &-title {
      font-size: 40px;
      line-height: 46px;
    }
    &-section-form-content-title {
      font-size: 30px;
      line-height: 36px;
    }
  }

  @include media-breakpoint-down(md) {
    &-title {
      font-size: 30px;
      line-height: 36px;
    }
    &-section-form-content-title {
      font-size: 25px;
      line-height: 30px;
    }
  }

  @include media-breakpoint-down(sm) {
    &-title {
      font-size: 25px;
      line-height: 30px;
    }
  }

}

