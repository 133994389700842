@import "./../_global";

.product-list-blur {
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
}

.image-gallery-thumbnail,
.image-gallery-thumbnail-image {
    border-radius: 10px;
}

.product {
    &-title {
        font-size: 30px;
        color: $color-blue;
        margin-top: 40px;
        font-weight: 500;
        line-height: 30px;
        @media screen and (max-width: 575.98px) { font-size: 25px; }

        &-city {
            color: #707070;

            &-dot {
                color: $color-green;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: green;
                border-radius: 50%;
                height: 3px;
                width: 3px;
                margin: 3px 10px;
            }
        }
    }

    &-gallery {
        .image-gallery-slide-wrapper.right {
            max-width: 30vw;
        }
        .image-gallery-thumbnails-wrapper.right {
            width: 124px;

            .image-gallery-thumbnail {
                width: 124px;
                border: solid 2px transparent;

                & + .image-gallery-thumbnail {
                    margin-top: 10px;
                }

                // &.active,
                // &:hover,
                // &:focus {
                //     border: solid 2px $color-green;
                // }
            }
        }
    }

    &-map {
        z-index: 98;
        @media screen and (max-width: 1499.98px) { height: 400px !important; }
        @media screen and (max-width: 991.98px) { height: 300px !important; }
        @media screen and (max-width: 575.98px) { height: 250px !important; }
    }

    &-reservation {
        box-shadow: 0px 3px 5px #00000014;
        border: 1px solid #b1b1b1;
        border-radius: 20px;
        padding: 20px 40px;

        .close-reservation {
            display: none;
        }

        //@include media-breakpoint-down(md) {
        @media screen and (max-width: 767.98px) {
            position: fixed;
            right: -500px;
            top: 50%;
            z-index: 99999;
            background: #fff;
            transition: right 0.5s linear;
            width: 500px !important;
            transform: translate(0, -50%);
            max-width: 100% !important;
            border: none;

            .close-reservation {
                display: block;
                position: absolute;
                top: 20px;
                box-shadow: 1px 0px 7px #000000;
                padding: 13px;
                border-radius: 100px;
                right: 20px;
                color: #000;
                cursor: pointer;
            }

            &.active {
                padding: 45px 20px !important;
                right: 0;
                height: 100vh;
                border-radius: initial;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        &-name {
            color: $color-blue;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;

            span {
                font-weight: 500;
            }
        }

        &-price {
            color: $color-blue;
            font-size: 12px;
            line-height: 20px;
            text-align: right;

            span {
                font-weight: bold;
                font-size: 20px;
            }
        }

        &-company {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
            border-top: 1px solid #b1b1b1;
            border-bottom: 1px solid #b1b1b1;

            @media screen and (max-width: 767.98px) {
                justify-content: center;
            }

            svg {
                font-size: 50px;
                margin: 10px 0;
                color: $color-blue;
                border-radius: 50%;
                border: 2px solid $color-green;
            }

            &-img {
                object-fit: cover;
                border-radius: 50%;
                width: 60px;
                height: 60px;
            }

            &-name {
                color: $color-blue;
                font-weight: bold;
                font-size: 18px;
                margin-left: 20px;
                margin-bottom: 0;
                text-decoration: none;
            }
        }

        &-resume-cart {
            color: $color-blue;
            width: 100%;
            border-top: 1px solid #b1b1b1;
            border-bottom: 1px solid #b1b1b1;

            th,
            td {
                padding: 2px 0;
            }
            thead th {
                padding: 10px 0 2px;
            }
            tfoot th {
                padding: 5px 0;
            }

            tfoot {
                font-weight: bold;
                font-size: 1.1em;
                border-top: 1px solid #b1b1b1;
            }

            td:nth-child(2) {
                text-align: right;
            }
        }

        &-request {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            &-offer,
            &-message {
                box-shadow: 0px 3px 6px #00000029;
                padding: 10px;
                text-align: center;
                margin: 10px -10px;

                p {
                    font-size: 14px;
                    margin: 5px 0;
                }
                
                .input-group-text {
                    border-radius: 0 0.375rem 0.375rem 0;
                    padding-bottom: 4px;
                    padding-top: 5px;
                }

                .input-price-offer,
                .input-message {
                    padding: 0.375rem 0.75rem;
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                &-buttons {
                    display: flex;
                    justify-content: center;
                    column-gap: 10px;
                }
            }
        }
    }

    &-h2 {
        position: relative;
        font-size: 24px !important;
        margin-bottom: 20px !important;
        margin-top: 80px;
        cursor: pointer;

        &-icon {
            display: flex;
            justify-content: space-between;
        }

        &::before {
            content: "";
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #b1b1b1;
        }
    }

    &-other-offers {
        background: #f5f5f5;
        border-radius: 20px;
        margin: 60px auto;
        padding: 3rem 0;

        &-h2 {
            text-align: center;

            @media screen and (max-width: 1198.99px) {
                font-size: 30px !important;
            }
        }

        .card {
            background-color: #fff;
            padding: 20px;
            margin: 0;
            box-shadow: none !important;
            border-radius: 15px;
        }

        .splide {
            .splide__slide {
                height: 100% !important;
            }

            .splide__arrow {
                background-color: #fff;
                color: $color-green;
                border: 1px solid $color-green;
    
                &:hover {
                    background-color: $color-green;
                    color: #fff;
                    border: 1px solid transparent;
                }
    
                &:disabled {
                    display: none;
                }
            }
        }


        @media (max-width: 991.98px) {
            .card-product {
                flex-direction: column;
                padding: 20px !important;

                &-pic {
                    width: initial;
                    height: initial;
                }
            }
        }
    }
}

.table-informations {
    width: 100%;

    tr:nth-child(odd) {
        background-color: #f5f5f5;
    }

    td {
        padding: 5px;

        &:first-child {
            font-weight: bold;
        }
    }
}



.Toastify__toast-container {
    width: 800px;
    max-width: 90%;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: left !important;
}
// .image-gallery-content.left .image-gallery-slide .image-gallery-image,
// .image-gallery-content.right .image-gallery-slide .image-gallery-image {
//     max-width: 400px;
// }

@media screen and (max-width: 991.98px) {
    .product-gallery .image-gallery-slide-wrapper.right {
        max-width: 100%;
    }

    .product-gallery .image-gallery-slide-wrapper.right,
    .product-gallery .image-gallery-thumbnails-wrapper.right {
        width: 100%;
    }

    .image-gallery-thumbnails .image-gallery-thumbnails-container {
        transform: translate3d(0px, 0px, 0px);
        transition: all 450ms ease-out 0s;
        display: flex;
        justify-content: center;
        column-gap: 10px;
        align-items: center;
    }

    .product-gallery .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnail {
        margin-top: 10px !important;
    }
}

.sticky-reservation {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-green;
    z-index: 9999;
    padding: 10px 0 15px;

    @media screen and (min-width: 768px) {
        display: none;
    }

    &-text {
        color: #fff;
        text-align: left;
        font-size: 18px;
        line-height: 23px;

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }

        &-price {
            font-size: 14px;
            color: #fff;
            margin-bottom: 0;

            b {
                font-size: 22px;
            }
        }
    }

    &-reserve {
        display: flex;
        align-items: flex-end;
        
        &-btn {
            background-color: #fff;
            color: $color-green;
            font-weight: bold;
            padding: 7px 0;
            font-size: 14px;
            line-height: 21px;
            width: 300px;
            margin-bottom: 0;
            border: 1px solid transparent;
    
            &:hover {
                color: #fff;
                background-color: $color-green;
                border: 1px solid #fff;
            }
        }
    }

}
