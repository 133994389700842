// Source mixin
@mixin custom-container($container-xxl-max-width,
                        $container-xl-max-width,
                        $container-lg-max-width,
                        $container-md-max-width,
                        $container-sm-max-width,
                        $container-xs-max-width
      ) {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(1.5rem * 0.5);
  padding-left: calc(1.5rem * 0.5);

  @media screen and (min-width: 575.98px) {
    max-width: $container-sm-max-width;
  }

  @media screen and (min-width: 767.98px) {
    max-width: $container-md-max-width;
  }

  @media screen and (min-width: 991.98px) {
    max-width: $container-lg-max-width;
  }

  @media screen and (min-width: 1199.98px) {
    max-width: $container-xl-max-width;
  }

  @media screen and (min-width: 1499.98px) {
    max-width: $container-xxl-max-width;
  }

  @media screen and (max-width: 575.98px) {
    max-width: $container-xs-max-width;
  }
}