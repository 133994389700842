@import "../_variables";

.footer {
    &-section-application {
        margin: 60px auto;
        border-radius: 20px;
        background-size: cover;
        background-position: center;

        &-content {
            @media screen and (max-width: 991.98px)  {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                text-align: center;
            }
        }

        &-title {
            @media screen and (max-width: 991.98px)  {
                margin-top: 60px;
            }

            @media screen and (max-width: 575.98px)  {
                font-size: 30px !important;
                line-height: 35px !important;
            }
        }

        &-text {
            max-width: 560px;
            color: $color-grey;
            font-size: 24px;
            line-height: 30px;
            font-weight: 500;
            margin-top: -1rem;

            @media screen and (max-width: 991.98px)  {
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (max-width: 575.98px)  {
                font-size: 18px;
                line-height: 20px;
            }
        }

        &-buttons {
            margin-top: 20px;

            &-badge {
                width: 144px;
                margin-right: 40px;

                @media screen and (max-width: 575.98px)  {
                    margin-bottom: 10px;
                }
            }

            @media screen and (max-width: 991.98px)  {
                margin-bottom: 60px;

                &-badge {
                    width: 144px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }

            @media screen and (max-width: 575.98px)  {
                margin-bottom: 20px;
            }
        }
    }
}

.app-footer {
    background-color: $color-blue;
    color: #fff;
    border-radius: 40px 40px 0px 0px;

    .container {
        display: flex;
        flex-direction: column;
    }

    &-content {
        width: 100%;
        padding: 90px 0;

        @include media-breakpoint-down(sm) {
            max-width: 90%;
            padding: 80px 0;
        }

        &-img {
            width: 90%;
            max-width: 340px;
            margin-bottom: 30px;
        }

        &-social {
            color: #fff;
            background-color: $color-green;
            padding: 5px;
            border-radius: 30px;

            &:hover {
                color: $color-white;
            }

            &:not(:last-child) {
                margin-right: 15px;
            }

            svg {
                width: 20px;
            }
        }

        &-buttons {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;

            &-badge {
                width: 144px;
                
                @media screen and (max-width: 575.98px)  {
                    width: 120px;
                    margin-right: 5px;
                    margin-left: 5px;
                }
            }
        }

        &-title {
            font-size: 24px;

            @include media-breakpoint-down(md) {
                margin-top: 40px;
                line-height: 30px;
            }
        }

        hr {
            width: 50px;
            height: 2px;
            background-color: $color-green;
            border: none;
            opacity: 1;
            margin-top: -10px;
            margin-bottom: 25px;
        }

        ul {
            margin: 0;
            padding: 0 0 2rem 0;
            list-style-type: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 7px;
                }

                a {
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        font-weight: bold;
                    }
                }
            }
        }

        &-text {
            font-size: 16px;
            line-height: 20px;
            color: $color-white;
            padding-left: 10px;
            max-width: 340px;

            @include media-breakpoint-down(lg) {
                max-width: 80%;
            }

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }

            &-bold {
                font-weight: bold;
                margin-top: 20px;
                width: 100%;
            }
        }

        &-button {
            display: block;
            background-color: transparent;
            padding: 0;
            border: none;
            color: $color-white;
            margin-top: 20px;
            text-decoration: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            font-family: $font-family;

            &:hover {
                color: $color-green;

                &::after {
                    margin-left: 22px;
                }
            }

            &:after {
                content: '→';
                margin-left: 17px;
                transition: 0.3s;
                color : $color-green;
            }
        }
    }

    &-bottom {
        width: 100%;
        border-top: 1px solid #fff;
        padding: 10px 0;

        p {
            margin: 0;
            font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
            max-width: 90%;
        }

        &-link {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: $color-green;
                font-weight: bold;
            }
        }
    }
}
