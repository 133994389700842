@import "../_variables";

.banner {
  &-wrapper {
    position: relative;
    min-height: 280px;
    background-position: center;
    background-size: cover;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-large {
      min-height: 380px;
      padding: 0 0 60px;
    }
  }

  &-title {
    color: $color-blue;
    font-size: 50px;
    line-height: 60px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;

    &-colored {
      color: $color-green;
    }
  }

  @include media-breakpoint-down(xl) {
    &-wrapper {
      margin-top: 0;
      min-height: 290px;

      &-large {
        padding: 20px 0;
      }
    }
    &-title {
      font-size: 40px;
      line-height: 46px;
    }
  }

  @include media-breakpoint-down(md) {
    &-wrapper {
      min-height: 190px;
    }
    &-title {
      font-size: 30px;
      line-height: 36px;
    }
  }

  @include media-breakpoint-down(sm) {
    &-wrapper {
      min-height: 190px;
    }
    &-title {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
