@import "../../_variables";

.category-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  height: 400px;
  width: 400px;
  padding: 55px 100px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 0 7px;
  border-radius: 20px;
  cursor: pointer;

  &-block {
    text-align: center;

    &-text {
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;
      color: $color-blue;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      color: $color-grey;

      &-item {
        margin: 10px 0;
      }
    }

    &-img {
      width: 100%;
      max-width: 150px;
      margin-bottom: 20px;
      object-position: bottom;
      height: 130px;
      object-fit: contain;
    }
  }

  @include media-breakpoint-down(lg) {
    height: 322px;
    width: 322px;
    padding: 20px;
  }

  @include media-breakpoint-down(md) {
    height: 222px;
    width: 222px;
    padding: 20px;

    &-block {
      &-text {
        font-size: 20px;
      }

      &-list {
        display: none;
      }

      &-img {
        max-width: 110px;
        height: 100px;
      }
    }
  }
}
