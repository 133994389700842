.own_reservations {
    padding: 70px 0;

    .profile-nav-tabs {
        justify-content: center;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
            background: #F8F8F8;
            width: fit-content;
            margin: 0 auto 40px;
            border-radius: 50px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 0 20px;
            width: 100%;
        }

        .nav-item {
            @include media-breakpoint-down(sm) {
                width: 33%;
            }

            .btn {
                &:hover {
                    color: $color-green;
                }
    
                @include media-breakpoint-down(sm) {
                    padding: 10px 20px;
                }
            }

            &:not(.active) {
                background-color: initial;
            }
        }
    }

    &_waiting {
        &_array {
            margin-bottom: 50px;
        }

        &_titre {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #000f48;
            font-size: 30px;
            line-height: 38px;
            font-weight: 700;
            margin-bottom: 10px;

            img {
                margin-right: 10px;
                border-radius: 100px;
            }

            span {
                font-weight: 500;
            }

            @media screen and (max-width: 1199.98px) {
                font-size: 25px;
                line-height: 30px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                line-height: 25px;
            }
        }
    }

    table {
        thead {
            tr {
                border-bottom: 2px solid #B1B1B1;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #B1B1B1;
                color: #707070;

                strike {
                    color: #B1B1B1;
                }
            }
        }

        th {
            font-weight: 500;
            font-size: 18px;
            color: $color-grey;
            padding: 15px 2px;

            &:nth-child(1) {
                width: 15%;
            }

            &:nth-child(2) {
                width: 15%;
            }

            &:nth-child(3) {
                width: 26%;
            }

            &:nth-child(4) {
                width: 20%;
            }

            &:nth-child(5) {
                width: 15%;
            }

            &:nth-child(6) {
                width: 09%;
            }
        }

        td {
            color: #707070;
            vertical-align: middle;
            padding: 8px 5px;
            min-height: 40px;
            
            @include media-breakpoint-down(md) {
                padding: 5px 5px;
            }
        }

        .btn {
            margin-right: 5px;
            margin-bottom: 0;
            font-size: 24px;
            padding: 0;
            width: 46px;
            height: 46px;
            border: none;

            &-outline-primary {
                background-color: $color-green-light;
            }

            &-outline-danger {
                background-color: $color-red-light;
            }

            &-message {
                color: $color-grey;

                &:hover {
                    color: $color-grey-light;
                    background-color: $color-grey;
                }
            }
        }

        .offre {
            &-accepted {
                color: $color-green;
            }

            &-declined {
                color: $color-red;
                text-decoration: line-through;
            }

            &-strike {
                color: #B1B1B1;
                text-decoration: line-through;
                margin-left: 1rem;
            }

            &-history {
                &-pending,
                &-refused {
                    color: #ED5252;
                }

                &-accepted {
                    color: #41C850;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding: 20px 0;

        .profile-nav-tabs {
            .btn {
                font-size: 14px;
                line-height: 16px;
                padding: 10px 25px;
            }
        }
    }
}

.own_reservations_waiting_mobilecard>.own_reservations_waiting_titre {
    position: sticky;
    top: 100px;
    background-color: $color-white;
    margin: 0;
    padding: 1rem 0;
    z-index: 10;

    &::before {
        content: '';
        position: absolute;
        background-color: $color-white;
        bottom: 0;
        height: 100%;
        width: 100vw;
        border-bottom: solid 1px #B1B1B1;
        left: 50%;
        margin-left: -50vw;
        z-index: -1;
    }
}

.card-reservation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem auto;
    border-radius: 20px;
    font-size: 16px;
    line-height: 20px;

    &:last-child{
        @media screen and (max-width: 576.98px) {
            margin-bottom : 2rem;
        }
    }

    &-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 10px;
    }

    &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        text-align: right;
    }

    &-title {
        color: $color-blue;
        font-weight: 600;
    }

    &-actionzone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        column-gap: 8px;

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border: none;
            margin-bottom: 0;
            padding: 0;
            font-size: 15px;
            line-height: 15px;

            &-outline-primary {
                background-color: $color-green-light;
            }

            &-outline-danger {
                background-color: $color-red-light;
            }

            &-message {
                color: $color-grey;

                &:hover {
                    color: $color-grey-light;
                    background-color: $color-grey;
                }
            }
        }
    }
}

.ReactModal__Content {
    width: 500px;
    height: 500px;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    min-width: 40%;
    min-height: 50vh;
    max-width: 90%;
    max-height: 90vh;
}

.mdw-calendar {
    width: 100%;

    .rmdp-calendar {
        width: 100%;
    }

    .rmdp-day-picker {
        justify-content: space-around;

        >div {
            border: 1px solid blue;
        }
    }
}