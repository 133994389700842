input[type="radio"] {
    vertical-align: text-bottom;
    margin-right: 15px;
    appearance: none;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    /* The background will be the radio dot's color. */
    background: none;
    /* The border will be the spacing between the dot and the outer circle */
    border: 4px solid #FFF;
    /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
    box-shadow: 0 0 0 1px #B1B1B1;

    &:checked {
        background: #41C850;
    }
}

.location-form {
    hr {
        margin: 40px 0;
    }

    &-title {
        color: #2E3D44;
        font-weight: bold;
        width: 100%;
        margin: .375rem 0 .75rem;
    }

    &-text {
        color: #707070;
        font-size: 14px;
    }

    .form-control + &-text,
    .form-group + &-text {
        //Pour viser les tips sous les inputs
        margin-top: 10px;
    }

    &-title-green {
        color: $color-green;
        font-size: 24px;
        font-weight: bold;
        width: 100%;
        margin: 20px 0;
    }

    &-category {

        input[type="radio"] {
            display: none;

            &:checked + label{
                border-radius: 10px;
                box-shadow: 1px 0px 7px #00000029;
                background-color: #EFFFF1;
            }
        }

        &-elements {
            @include media-breakpoint-down(md) {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
        }

        &-block {
            width: 160px;
            height: 160px;
            padding: 20px;
            text-align: center;
            float: left;
            cursor: pointer;
            border-radius: 10px;
            border: solid 4px transparent;
            margin-right: 16px;

            img {
                max-width: 100px;
            }

            &-image {
                height : 75px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
            }

            &-text {
                color: $color-blue;
                font-size: 1rem;
                line-height: 1.1rem;
                font-weight: 600;
                margin-top: 8px;
            }

            &:hover {
                border-radius: 10px;
                box-shadow: 1px 0px 7px #00000029;
            }

            @include media-breakpoint-down(md) {
                padding: 10px;
                margin-right: 10px;
            }
        }

        &-infos {
            font-size: 24px;
            color: $color-grey;
            margin-top: 50px;
            font-weight: normal;
        }
    }

    &-specificity, &-fuel {
        &-choice {
            display: block;
            margin-bottom:10px;
        }
    }

    &-brand {
        margin-bottom: 40px;

        &-list {
            width: 30%;
            min-width: 300px;
        }
    }

    &-registration {
        margin-bottom: 25px;

        input {
            width: 30%;
            min-width: 300px;
        }
    }

    &-price {
        > .form-group {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }

            input {
                width: 30%;
                min-width: 300px;

                & + p { /* Bloc €/jour */
                    font-size: 14px;
                    color: #484848;
                }
            }

            p {
                margin-left: 10px;
            }
        }
    }

    &-calendar {
        .rmdp-full-year {
            grid-gap: 4rem;
            max-width: 100%;

            @media screen and (max-width: 1198.98px) {
                grid-gap: 3rem;
                grid-template-columns: 1fr 1fr;
            }
            @media screen and (max-width: 767.98px) {
                grid-gap: 2rem;
                grid-template-columns: 1fr;
            }
        }

        .rmdp-month-name {
            font: normal normal 600 16px/20px Outfit;
            letter-spacing: 0;
            color: #000F48;
        }

        .rmdp-week-day {
            font: normal normal 400 14px/18px Outfit;
            letter-spacing: 0;
            color: #8D8D8D;
        }

        .rmdp-day {
            font: normal normal 400 16px/20px Outfit;

            .sd {
                color: #000F48;
            }

            &.rmdp-disabled {
                color: #DDDDE0;
            }

            &.rmdp-today .sd {
                color: $color-white;
            }

            &.rmdp-selected span:not(.highlight) {
                color: $color-red !important;
                background-color: $color-red-light !important;
                box-shadow: 0 0 3px $color-red-light !important;
            }
        }

        .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
            background-color: $color-red !important;
            color: #fff !important;
        }
    }

    &-map {
        position: relative;

        .suggestions, .no-suggestions {
            top: calc( 100% + 1px );
            border: solid 1px $color-green-light;
            grid-template-columns: 1fr 1fr;

            li {
                cursor: pointer;
                list-style: none;
                margin: .5rem 0;
            }
        }
    }

    &-osmap {
        z-index: 8;
        margin-top: 40px;
        margin-bottom: 80px;
    }

    &-upload {
        display: flex;

        .image-item {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            height: calc(135px + 42px);

            img {
                max-height: 135px;
            }

            &__btn-wrapper .btn {
                margin: 5px;
            }
        }
    }
}

.form-inputsgroup {
    margin-top: 50px;
    position: sticky;
    background-color: $color-white;
    padding: 15px 0 5px;
    bottom: 0;
    z-index: 10;
    // box-shadow: 0px 3px 6px #00000029;
    width: 100vw;
    left: 0;
    margin-left: -50vw;
    margin-right: -50vw;

    .form-check.form-switch {
        display: inline-block;
        margin-left: 1rem;

        @include media-breakpoint-down(sm) {
            .form-check-label {
                font-size: 13px;
            }
        }
    }

    .btn.btn-outline-danger {
        margin-left: 3rem;
    }

    .btn {
        @include media-breakpoint-down(sm) {
            font-size: 14px;
            padding: 5px 20px;
        }
    }
}

.btnActionUpload {
    display: block;
    width: 135px;
    height: 135px;
    background-color: initial;
    border: dashed 2px $color-green;
    border-radius: 10px;
}

.btnFileUpload {
    width: 135px;
    background-color: initial;
    border: dashed 2px $color-green;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}