@import '../variables';

.searchfilters {
  &-form {
    &-base {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      width: 600px;
      max-width: 100%;
      min-width: 60%;
      margin: 0;
      border-radius: 10px;
      border: 2px solid $color-green;
      box-shadow: 0 0.25rem 0.625rem rgba(86, 88, 89, 0.2);
      padding: 10px 70px 10px 19px;
    }

    &-mobile {
      background-color: #FAFAFA;
      bottom: 0;
      height: 100%;
      overflow: auto;
      position: fixed;
      right: -100%;
      top: 0;
      transition: right .3s ease 0s;
      width: 100%;
      z-index: 10000;

      .cross {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        left: 10px;
        top: 15px;
        border: 1px solid #707070;
        cursor: pointer;

        &::before {
          background: #707070;
          position: absolute;
          display: block;
          content: "";
          width: 60%;
          height: 1px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
          display: block;
          background: #707070;
          position: absolute;
          content:'';
          width: 1px;
          height: 60%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      &-btn {
        border-radius: 30px;
        background-color: #41C850;
        color: #fff;
        font-size: 19px;
        line-height: 24px;
        font-weight: 600;
        padding-top: 10.5px;
        padding-bottom: 10.5px;
        text-align: center;
        width: calc(100% - 20px);
        margin: auto;
        display: block;
        border: none;
        margin-top: 30px;
      }
    }
  }

  &-item {
    width: 33.333%;
    position: relative;

    &.mobile {
      width: 100%;
      cursor: pointer;
    }

    &-title {
      color: $color-blue;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 0;

      &-primary {
        color: $color-green;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 0;
      }
    }

    input,
    &-text,
    .headerSearchText {
      border: none;
      color: #8d8d8d;
      line-height: 20px;
      font-size: 12px;
      width: 100%;
      padding: 0.125rem 0.75rem;
      padding-left: 0;
      display: flex;

      &:focus-visible {
        box-shadow: none;
        outline: none;
      }
    }

    .suggestions,
    .no-suggestions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 10px;
      column-gap: 25px;
      position: absolute;
      top: calc(100% + 20px);
      left: -20px;
      width: 700px;
      background-color: $color-white;
      padding: 10px;
      border-radius: 20px;
      list-style: none;
      z-index: 10;
      box-shadow: 0 0.25rem 0.625rem rgba(86, 88, 89, 0.2);

      @include media-breakpoint-down(xl) {
        left: 0;
      }

      &-img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        margin-right: 20px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 0 5px;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
        color: #2E3D44;
      }

      li:hover,
      .suggestion-active {
        color: #000;
        font-weight: bold;
        background: #f0f0f0;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .rdrCalendarWrapper {
      position: absolute;
      left: 0;
      top: calc(100% + 20px);
      z-index: 10;
      background: $color-white 0 0 no-repeat padding-box;
      box-shadow: 0 3px 6px #00000029;
      border-radius: 24px;
      opacity: 1;
    }

    .rdrMonthAndYearPickers select {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $color-blue;
    }

    .rdrWeekDays {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
      color: #8d8d8d;
      text-transform: capitalize;
    }

    .rdrPprevButton {
      background-color: $color-green;
      -webkit-mask-image: url(../../images/chevron-left-solid.svg);
      mask-image: url(../../images/chevron-left-solid.svg);
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin: 0 10px;
    }

    .rdrPprevButton i {
      display: none;
    }

    .rdrNextButton {
      background-color: $color-green;
      -webkit-mask-image: url(../../images/chevron-right-solid.svg);
      mask-image: url(../../images/chevron-right-solid.svg);
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin: 0 10px;
    }

    .rdrNextButton i {
      display: none;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      color: $color-blue;
      font-weight: 600;
    }

    .rdrDayNumber span {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      color: $color-blue;
      font-weight: 600;
    }

    .rdrMonthAndYearPickers select {
      color: $color-blue;
      text-transform: capitalize;
    }

    .rdrDayToday .rdrDayNumber span:after {
      content: none;
    }

    .rdrDateDisplayWrapper {
      display: none;
    }

    .rdrDayStartOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrInRange {
      border-radius: initial;
    }

    .rdrDayDisabled {
      opacity: 0.5;
    }

    .rdrDayPassive {
      opacity: 0;
    }

    .rdrStartEdge, .rdrEndEdge {
      background: $color-green;
      position: absolute;
      top: 1px;
      left: 4px;
      right: 0;
      bottom: 5px;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      opacity: 1;
    }

    .rdrSelected, .rdrInRange {
      background: $color-green;
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      bottom: 5px;
      border-radius: initial;
      opacity: 0.22;
    }

    .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayInPreview {
      border-radius: initial;
      left: 0;
      right: 0;
      display: none;
    }

    .rdrDayStartPreview, .rdrDayEndPreview {
      border-width: 1px;
      top: 1px;
      left: 4px;
      right: 0;
      bottom: 5px;
      border-radius: 50%;
      height: 35px;
      width: 35px;
    }

    .rdrDayInPreview {
      top: 5px;
      left: 0;
      right: 0;
      bottom: 5px;
      border-radius: initial;
      display: none;
    }
  }

  &-form-mobile {
    .searchfilters-item {
      position: relative;
      padding: 20px 50px;
      box-shadow: 1px 3px 6px #00000029;
      border-radius: 20px;
      width: calc(100% - 20px);
      margin: auto;
      background-color: #fff;

      @include media-breakpoint-down(xl) {
        .suggestions {
          top: 90px !important;
        }
      }

      .suggestions {
        width: 100%;
        top: 105px;
      }

      .rdrCalendarWrapper {
        top: 105px;
      }
    }
  }

  &-separator {
    height: 46px;
    width: 2px;
    background-color: $color-green;
    margin: 0 12px;
  }

  &-btn {
    position: absolute;
    right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: $color-white;
    background-color: $color-green;
    width: 80px;
    height: 71px;
    font-size: 28px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @include media-breakpoint-down(xl) {
    &-form-base {
      width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    &-form-base {
      padding: 5px 60px 5px 10px;
    }

    &-item {
      &-title {
        font-size: 13px;
      }
      &-text {
        font-size: 14px;
      }
    }

    &-btn {
      width: 60px;
      height: 60px;
      font-size: 20px;
    }
  }
}