.breadcrumb-list {
    padding: 10px 0;
    margin: 0;

    .breadcrumb-item {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.55);

        a {
            color: #000;
            text-decoration: none;

            &:hover {
                color: #009ba6;
            }
        }
    }
}
