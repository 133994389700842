.react-chat-messageBox {
    max-width: 80%;
    background: none;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    position: relative;
}
  
.react-chat-messageBoxLeft {
    padding-left: 40px;
    align-items: flex-start;
    text-align: left;
}
  
.react-chat-messageBoxRight {
    padding-right: 40px;
    align-self: flex-end;
    align-items: flex-end;
    text-align: right;
}
  
.react-chat-message-buttonGroupLeft a, .react-chat-message-buttonGroupLeft button {
    text-align: center;
    color: #212529;
}
  
.react-chat-message-buttonGroupRight a, .react-chat-message-buttonGroupRight button {
    text-align: center;
    color: #007bff;
}
  
.react-chat-message-button {
    cursor: pointer;
    display: inline-block;
    background-color: #fff;
    width: 100%;
    border-radius: 12px;
    padding: 5px;
    margin: 2px !important;
}
  
.react-chat-message-button:hover {
    text-decoration: none;
}
  
.react-chat-message {
    max-width: 100%;
    margin: 2px 0 0;
    position: relative;
    transition: margin 0.2s ease-in-out;
}
  
.react-chat-messageLeft {
    align-items: flex-start;
    text-align: left;
}
  
.react-chat-messageRight {
    align-self: flex-end;
    align-items: flex-end;
    text-align: right;
}
  
.react-chat-bubble {
    transition: box-shadow 0.2s ease-in-out;
    order: -1;
    box-shadow: none;
    border-radius: 12px;
    padding: 8px 16px;
    border-width: 1px;
    border-style: solid;
    line-height: 1.25;
    word-wrap: break-word;
    font-size: 1rem;
    width: fit-content;
    text-align: left;
    word-break: break-word;
    white-space: break-spaces;
}
  
.react-chat-leftBubble {
    border-bottom-left-radius: 2px;
    border-color: #e4e7eb;
    color: #212529;
    background-color: #e4e7eb;
    float: left;
}
  
.react-chat-rightBubble {
    border-bottom-right-radius: 2px;
    border-color: #007bff;
    color: #fff;
    background-color: #007bff;
    float: right;
}
  
.react-chat-avatar {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border-style: none;
    bottom: 20px;
}
  
.react-chat-avatarLeft {
    left: 0;
}
  
.react-chat-avatarRight {
    right: 0;
}
  
.disabled {
    opacity: 0.3;
    background-color: #e4e7eb;
}
  
.react-chat-additional {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #5f6368;
    font-size: 13px;
}
  
.react-chat-bubbleWithError {
    position: relative;
}
  
.react-chat-error {
    position: absolute;
    fill: red;
}
  
.react-chat-errorLeft {
    right: -30px;
}
  
.react-chat-errorRight {
    left: -30px;
}
  
.react-chat-notification {
    width: 100%;
    word-break: break-word;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.react-chat-typing-indicator {
    background-color: #e4e7eb;
    width: auto;
    border-radius: 50px;
    /*padding: 20px;*/
    display: table;
    margin: 0 auto;
    position: relative;
}
  
.react-chat-typing-indicator span {
    height: 1rem;
    width: 1rem;
    float: left;
    margin: 0 1px;
    background-color: #9e9ea1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}
  
.react-chat-typing-indicator span:nth-of-type(1) {
    -webkit-animation: 1s blink infinite 0.3333s;
    animation: 1s blink infinite 0.3333s;
}
.react-chat-typing-indicator span:nth-of-type(2) {
    -webkit-animation: 1s blink infinite 0.6666s;
    animation: 1s blink infinite 0.6666s;
}
.react-chat-typing-indicator span:nth-of-type(3) {
    -webkit-animation: 1s blink infinite 0.9999s;
    animation: 1s blink infinite 0.9999s;
}
  
@-webkit-keyframes blink {
    50% {
        opacity: 1;
    }
}
  
@keyframes blink {
    50% {
        opacity: 1;
    }
}