.company {
  &-banner {
    padding: 50px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 60px;
  }

  &-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 50px;
    box-shadow: 0 0 6px #00000029;
    border: 1px solid #B1B1B1;
    border-radius: 20px;
    background-color: #fff;

    &-explanations {
      display: flex;

      &-img {
        border: solid 15px #EFFFF1;
        //outline: solid 2px $color-green; /* A cause de SAFARI */
        box-shadow: 0 0 0 2px $color-green;
        border-radius: 50%;
        background-color: $color-white;
        color: $color-blue;
        width: 150px;
        height: 150px;
      }

      &-name {
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        color: $color-blue;
      }

      &-place {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #707070;

        svg {
          border: initial;
          outline: initial;
          border-radius: initial;
          font-size: initial;
          color: $color-grey;
        }
      }
    }

    &-info {
      margin: 40px 0 0 40px;
    }

    &-check {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 35px;

      &-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #B1B1B1;

        &:last-child {
          margin-left: 25px;
        }
      }
    }

    @media screen and (max-width: 576.98px) {
      align-items: center;
      padding: 20px;

      &-explanations {
        flex-direction: column;
        align-items: center;
      }

      &-info {
        margin: 20px 0 0;
        text-align: center;
      }

      &-check {
        flex-direction: column;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &-cardcontainer {
      .solu-btn-sm {
        padding: 5px 15px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        &::after { display: none; }
      }

      .card-product {
        flex-direction: column;
        padding: 20px !important;

        &-pic {
            width: initial;
            height: initial;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &-cardcontainer {
      .card-product {
        flex-direction: row;
        padding: 10px !important;

        &-pic {
            width: 150px;
            height: 150px;
        }
      }
    }
  }
}
